<table class="table table-borderless">
    <thead class="header-forum">
        <tr>
            <th colspan="2">
                <h5>Dúvida: {{subject}}</h5>
            </th>
        </tr>
      <tr>        
        <th scope="col">Respostas</th>
        <th scope="col">Autor</th>        
      </tr>
    </thead>
    <tbody>        
      <tr class="border-list" *ngFor="let comment of comments">        
        <td>
            <div [innerHTML]="comment.text"></div>
        </td>
        <td>{{comment.author[0].name}}</td>
      </tr>
      <tr>
          <td>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="goBack()" >Voltar</button>&nbsp;&nbsp;
            <button type="button" class="btn btn-primary" (click)="openModalComments($event)" >Responder</button>
          </td>
      </tr>  
    </tbody>
  </table>
  <!-- Modal -->
  <div class="modal fade" id="modalNovoComments" #modalCommentsNovo tabindex="-1" role="dialog" aria-labelledby="modalNovoComments" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalNovoComments">Responder um duvida.</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal(modalCommentsNovo)">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="input-group my-2">
             <Label>{{subject}}</Label>             
          </div>
          <ckeditor [editor]="Editor" data="<p>Hello, world!</p>" [(ngModel)]="model.text"></ckeditor>          
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal(modalCommentsNovo)">Desistir</button>
          <button type="button" class="btn btn-primary" (click)="saveComments(model)">Guardar</button>
        </div>
      </div>
    </div>
  </div>
