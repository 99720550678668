import { Component, OnInit } from '@angular/core';
import { POTENCIAL_API } from 'src/app/app.api';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Form, FormControl } from '@angular/forms';
import swal from 'sweetalert2';
import { UpdatePassword } from 'src/app/user/user.model';
import { UserService } from 'src/app/user/user.service';

@Component({
  selector: 'app-redefinepassword',
  templateUrl: './redefinepassword.component.html'
})
export class RedefinepasswordComponent implements OnInit {

  link: string
  redefinirForm : FormGroup
  email : string
  
  constructor(private userService: UserService,
              private http: HttpClient,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder) { 
      this.link = this.activatedRoute.snapshot.params['link']
    }

  ngOnInit() {

    this.redefinirForm = this.fb.group({
      newpassword: this.fb.control(''),
      confirmpassword: this.fb.control('')
    })

    this.http.get<any>(`${POTENCIAL_API}/v1/api/valida_link/${this.link}`) 
    .subscribe(res => {

      if(res==false){
        swal({
          title: 'Gypsum',
          text: 'Link inválido!',
          type: 'error',
          showConfirmButton: false,
          timer: 3000
        })
        setTimeout( () => {
          this.router.navigate(['/login'])
        }, 2500 );
      } else {
        this.email = res
      }
    })
  }

  updatePassword(updatePassword: UpdatePassword){
    updatePassword.email = this.email

    this.userService.updateRecoverPassword(updatePassword)
    .subscribe(res => {
      console.log(res.error)
      if(res.error == undefined){
        swal({
          title: 'Gypsum',
          text: 'Senha alterada!',
          type: 'success',
          showConfirmButton: false,
          timer: 1500
        })
        setTimeout( () => {
          swal.close()
          this.router.navigate(['/login'])
        }, 2000 );
      } else {
        swal({
          title: 'Gypsum',
          text: res.error,
          type: 'error',
          showConfirmButton: false,
          timer: 1500
        })
      }
    })    
  }  
}
