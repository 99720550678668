import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot} from '@angular/router'
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {LoginService} from "./login/login.service";

@Injectable()
export class LoggedInGuard implements CanLoad, CanActivate {

  constructor(private loginService: LoginService) {

  }

  checkAuthentication(route: Route): boolean {
    const loggedIn: boolean = this.loginService.isLoggedIn()
    const cadastro: boolean = this.loginService.isCadastro()
    const token : string = this.loginService.getToken()

    if(!loggedIn) {
        this.loginService.handleLogin(`${route.path}`)
    }

    if(loggedIn && !cadastro){  
      this.loginService.handleCadastro(`aluno/cadastro/${token}`)
    }

    return true
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkAuthentication(route)
  }

  canActivate(activatedRoute: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean {
    return this.checkAuthentication(activatedRoute.routeConfig)
  }
}