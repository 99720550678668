<div class="card shadow p-3 mb-5 bg-white rounded">
  <form #cadastroForm="ngForm">
  
    <h1>Mini teste módulo: {{modulo?.nome}}</h1>
    
    <!-- One "tab" for each step in the form: -->
      <div class="tab" style="display: block; margin-top: 50px;" *ngFor="let pergunta of listPerguntas; let i = index;">
        <div>{{pergunta?.titulo}}:
          
          <div style="padding: 20px;">
            <img [src]="retornarFoto(pergunta.id)" alt="" (error)="checkExistsPicture($event)">
          </div>
          <div class="form-check" *ngFor="let resposta_ of pergunta?.questoes_items">
              <input class="form-check-input" type="radio"  name="{{pergunta.id}}" id="resposta{{i}}" [value]="resposta_" *ngIf="!getStatusResposta(resposta_) && !perguntasResposdidas"   ngModel required>
              <label class="form-check-label" for="resposta{{i}}" >
                  <span style='font-size:15px;' *ngIf="getStatusResposta(resposta_) && perguntasResposdidas">&#9673;</span><!--bullet marcar resposta respondida-->
                  <span style='font-size:15px;' *ngIf="!getStatusResposta(resposta_) && perguntasResposdidas">&#9711;</span><!--bullet marcar respostas nao selecionadas-->
                  
                {{resposta_.titulo}}
              </label>&nbsp;&nbsp;
              <span style='font-size:20px; color:red;'  *ngIf="getStatusResposta(resposta_) && perguntasResposdidas && resposta_.correta===0">&#10007;</span>
              <span style='font-size:20px; color:green' *ngIf="getStatusResposta(resposta_) && perguntasResposdidas && resposta_.correta===1">&#10004;</span>
          </div>
          
        </div>
      </div>
      <div class="form-group has-feedback" style="margin-top: 14px;">
        <button (click)="gravar(cadastroForm.value)" type="button" class="btn btn-info btn-sm" [disabled]="!cadastroForm.form.valid" *ngIf="!perguntasResposdidas"> Gravar </button>&nbsp;
      </div>
    </form>
</div>
