import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

import { POTENCIAL_API } from '../app.api';
import { User, UpdatePassword } from './user.model';

@Injectable()

export class UserService{

  constructor(private http: HttpClient){}
   
  /*cadastroFindId(id: string): Observable<CadastroBasico> {
    return this.http.get<CadastroBasico>(`${FOTONAUTAS_API}/get_user_id/${id}`)
  }*/

  // cadastroFindEmail(email: string): Observable<string> {
  //   return this.http.get<string>(`${POTENCIAL_API}/get_user/${email}`)
  // }

  checarEmail(email: string): Observable <boolean>{
    return this.http.get<boolean>(`${POTENCIAL_API}/v1/check_user_email/${email}`)
  }
  
  destroy(id): Observable<any> {
    return this.http.delete<any>(`${POTENCIAL_API}/v2/api/users/${id}`)
  }

  updatePassword(updatePassword:UpdatePassword): Observable<any> {
    return this.http.put<any>(`${POTENCIAL_API}/v2/api/users/updatepassword`, updatePassword)
  }
  
  updateRecoverPassword(updatePassword:UpdatePassword): Observable<any> {
    return this.http.put<any>(`${POTENCIAL_API}/v2/api/users/updateRecoverPassword`, updatePassword)
  }

  usuarios(): Observable<User[]> {
    return this.http.get<User[]>(`${POTENCIAL_API}/v2/api/users`)
  }

  usuariosLogado(): Observable<User> {
    return this.http.get<User>(`${POTENCIAL_API}/v2/api/users/logado/dados`)
  }

  view(id: string): Observable<User> {
    return this.http.get<User>(`${POTENCIAL_API}/v2/api/users/${id}`)
  }

  sendMail(email: string): Observable<any>{
    return this.http.get<any>(`${POTENCIAL_API}/v1/api/recupera_senha/${email}`)
  }

  update(id:string, usuario: User): Observable<User>{
    return this.http.put<User>(`${POTENCIAL_API}/v2/api/users/${id}`, usuario)
  }
}