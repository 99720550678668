import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import swal from 'sweetalert2'
import { AlunoService } from './aluno.service'
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router'


@Component({
  selector: 'app-aluno',
  templateUrl: './aluno.component.html',
  styleUrls: ['./aluno.component.scss']
})
export class AlunoComponent implements OnInit {


  cadastroForm: FormGroup
  token= ""

  constructor(private fb: FormBuilder, 
              private alunoService: AlunoService,
              private route: Router,
              private router: ActivatedRoute ) { 
                this.token = this.router.snapshot.params['token']
              }

  ngOnInit() {
    this.cadastroForm = this.fb.group({
      nome: this.fb.control('', [Validators.required]),
      nif: this.fb.control('', [Validators.required]),
      
    })
  }

  save(user){
    this.alunoService.cadastro(user, this.token).subscribe(result=>{
      this.alunoService.obterFormandosDados(this.token).subscribe(formando=>{
        let user = formando
        let user_ = JSON.parse(window.localStorage.getItem("user"))
        user_.nome = user.nome  
        user_.nif = user.nif
        window.localStorage.setItem("user", JSON.stringify(user_))
        swal({
          title: 'Potencial Global',
          text: 'Formando atualizado com sucesso!',
          type: 'success',
          showConfirmButton: false,
          timer: 3000
        })
        setTimeout(() => 
        {
          this.cadastroForm.reset()
          this.route.navigate(['/area-aluno'])
        },
        2000);  
      })
    }, (error)=>{
      swal({
        title: 'Erro!',
        text: 'Token inválido.',
        type: 'warning',
        showConfirmButton: false,
        timer: 2000
      })
    }
    )
  }

}
