import { Component, OnInit, Output, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BACKEND_FILE } from '../../app.api'
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { TouchSequence } from 'selenium-webdriver';
import { CursoService } from '../curso.service';
import swal from 'sweetalert2';
import { EventEmitter } from 'events';
import { PdfViewerComponent } from 'ng2-pdf-viewer';

export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37
}

@Component({
  selector: 'app-curso-pdf',
  templateUrl: './curso-pdf.component.html',
  styleUrls: ['./curso-pdf.component.scss']
})
export class CursoPdfComponent implements OnInit {

  conteudo: any
  pdfSrc: SafeUrl
  url: string

  @Output() statusConteudo = new EventEmitter()
  @ViewChild(PdfViewerComponent, {static: false})
  private pdfComponent: PdfViewerComponent;

  page: number = 1;
  totalPages: number;
  isLoaded: boolean = false
  extFile: string
  doc: string
  concluido: boolean = false
  conteudoId: any
  conteudoItemId: any
  cursoId:any
  concluidoConteudoItem: any
  materialStatus:any
  currentContent: any = {}
  
  

  constructor(private router: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private cusroServide: CursoService,
    private route: Router) { 
    this.conteudo = this.router.snapshot.params['conteudo']
    this.conteudoId = this.router.snapshot.params['conteudoId']
    this.cursoId = this.router.snapshot.params['cursoId']
    this.conteudoItemId = this.router.snapshot.params['conteudoItemId']

    this.extFile = this.conteudo.substring(this.conteudo.indexOf('.')+1, this.conteudo.length)
    if(this.extFile === 'pptx'){
      this.doc = `${BACKEND_FILE}/apostilas/${this.conteudo}`
    }
  }



  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {    
    if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
      this.nextPage() 
    }

    if (event.keyCode === KEY_CODE.LEFT_ARROW) {
      this.prevPage()
    }
  }


  ngOnInit() {
    this.loadContaudo(this.conteudo)
    this.router.params.subscribe (res => {
      console.log(res)
   })
   this.obterConteudoStatus()
  }

  loadContaudo(conteudo){
    this.cusroServide.getConteudo(conteudo).subscribe(conteudo=>{
      this.pdfSrc = conteudo
    })
  }

  pageRendered() {
    this.pdfComponent.pdfViewer.currentScaleValue = 'page-fit';
  }

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
  }

  nextPage() {
    if(this.page == this.totalPages){
      this.page =   this.totalPages
    }else{
      this.page++;
    }

    this.setCurrentContent()
    
    // window.scroll(0,0);
  }

  prevPage() {
    if(this.page > 1){
      this.page--
    }
    this.setCurrentContent()
    
    // window.scroll(0,0);
  }

  concluirConteudo(){
    this.conteudoId = this.router.snapshot.params['conteudoId']
    let data = {capituloId: this.conteudoId, conteudo:[{dataConclusao: new Date(), conteudoItemId: this.conteudoItemId}]}

    this.cusroServide.concluirModulo(this.conteudoId, data).subscribe(result=>{
      swal({
        title: 'Potencial Global',
        text: "Conclusão do conteúdo realizado",
        type: 'success',
        showConfirmButton: false,
        timer: 3000
      })
      this.obterConteudoStatus()
      this.route.navigate(['area-aluno']);
    },(error)=>{
      swal({
        title: 'Potencial Global',
        text: 'Não foi possivel conlcui no momento, por fafor tente mais tarde.',
        type: 'error',
        showConfirmButton: false,
        timer: 3000
      })
    })

  }

  obterConteudoStatus(){
    this.cusroServide.obterConteudoStatus().subscribe(conteudoStatus=>{
      this.checkConteudoStatus(conteudoStatus)
      //
      this.concluidoConteudoItem = conteudoStatus
      this.concluido = this.checkMaterialStatus(this.conteudoId, this.conteudoItemId)
      // this.checkMaterialStatus(conteudoStatus.conteudo)
      console.log(conteudoStatus)
      this.statusConteudo.emit(conteudoStatus)
    })

  }

  checkConteudoStatus(respostas){
    if(respostas.findIndex(x => x.capituloId === this.conteudoId) >=0){
      this.concluido = true
    }else{
      this.concluido = false
    }
    
  }

  //checar se o material foi finalizado.
  checkMaterialStatus(capituloId, conteudoItemId){
    let status: boolean = false
    let conteudoItem = this.concluidoConteudoItem.find(conteudo => conteudo.capituloId === capituloId)
    if(conteudoItem != undefined && conteudoItem.conteudo.findIndex(x => x.conteudoItemId === conteudoItemId) >=0){
      status = true
    }else{
      status = false    
    }
    return  status
  }


  setCurrentContent(){
    this.cusroServide.setCurrentContent(this.route.url, this.page)    
  }
 
  

}
