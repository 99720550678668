import { Component, OnInit, Input, ViewChild, ElementRef, QueryList } from '@angular/core';
import { LoginService } from '../security/login/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../user/user.service';
import { BACKEND_FILE } from '../app.api'
import { CursoService } from '../curso/curso.service';
import swal from 'sweetalert2';
import { RespostaService } from '../respostas/respostas.service';
import { MenuService } from './menu.service';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input() src:string;
  tipo:string
  loggedIn: boolean
  logo: string
  logoEmpresa:string 
  modulos: any = []
  cursoId: any
  tituloCurso: any
  hideTitle: boolean = true
  moduloId: any
  curso: any
  conteudoFinalizado: any =[]
  perguntasResposdidas:  boolean = false
  respostasRealisadas: any
  listPerguntas: any
  arrowIcon: string = "fa-angle-right"
  checkMenuToggle: boolean = false
  ariaExpanded: boolean = false
  isCollapsed = "" 
  quantidadeModulosRespondidos = 0
  quantidadeTotalModulos = 0
  conteudoActive = 0

  @ViewChild('submenuCollaps') submenuCollaps: QueryList<ElementRef>;

  constructor(  private loginService: LoginService, private route: Router,
                private cadastroService: UserService,
                private cursoService: CursoService,
                private router: ActivatedRoute,
                private cusroServide: CursoService,
                private respostaService: RespostaService,
                private menuService: MenuService
                ) { 

    this.loggedIn = this.isloggedin()
    this.logo = this.loginService.user().logo
    this.cursoId = this.loginService.user().cursoId
    this.tituloCurso = this.loginService.user().nomeCurso
    

    
    
  }

  ngOnInit() {
    this.logoEmpresa = !this.logo  ? '../assets/img/LogosPG-01.png' :`${BACKEND_FILE}/${this.logo}`
    if(this.loggedIn){
      this.loadModulos()
      this.obterConteudoStatus()
      this.getPerguntasRespondidas()
    }
  }
  
  isloggedin(){
    return this.loginService.isLoggedIn()
  }

  closeToggle(event: any){
    let body = document.getElementsByTagName('body')[0]
    let close = document.getElementById("sidebar-close")
    let titleSidebar = document.getElementById("titleSidebar")
    if(!body.classList.contains('is-collapsed')){
      body.classList.add('is-collapsed');
      close.classList.add('show-close-button')
      titleSidebar.classList.add('hide-close-title')
      
    }else{
      body.classList.remove('is-collapsed')
      close.classList.add('hide-close-button')
      titleSidebar.classList.remove('hide-close-title')
      
    }        
  }

  updateUrl($event){
    this.src = this.logoEmpresa
  }


  loadModulos(){
    swal({
      title: 'Carregando dados...'
    });
    swal.showLoading(); 
    this.cursoService.listModulos(this.cursoId).subscribe(modulos=>{
      this.modulos = modulos[0].Modulo
      this.modulos.sort((a, b) => parseFloat(a.ordem) - parseFloat(b.ordem));
      this.curso = modulos[0]
      console.log(this.modulos)
      
      this.getCountModulos()
      this.getModulosRealizados()
      this.setDataProgressBar()

      window.localStorage.setItem("nomeCurso", this.curso.nome)
      swal.close()
    },(error)=>{
      swal({
        title: 'Erro!',
        text: 'Não foi possivel carregar os modulos ou não existe modulo cadastrado para o curso.',
        type: 'warning',
        showConfirmButton: false,
        timer: 2000
      })
    })
  }

  //01/04/2020 - mudança pra realizar a apresentação do conteudo em cards.
  //este metodo podes ir para um modulo mais generico.
  goConteudo(conteudo, tipo, conteudoId, conteudoItemId){
    //usar assim par fazer refresh no componente
    console.log("conteudo", conteudo)
    switch (tipo) {
      case 'A':
        //this.route.routeReuseStrategy.shouldReuseRoute = () => false
        this.route.navigate(['area-aluno/cursos/conteudo',this.cursoId, conteudoId,conteudoItemId,conteudo])
        break;
      case 'Y':
          //this.route.routeReuseStrategy.shouldReuseRoute = () => false
          this.route.navigate(['area-aluno/cursos/conteudo/video',this.cursoId,conteudoId, conteudoItemId,conteudo ])
        break;
      case 'G':
          window.open(conteudo, '_blank');
        break;
      case 'V':
        //this.route.routeReuseStrategy.shouldReuseRoute = () => false
        this.route.navigate(['area-aluno/cursos/conteudo/video',this.cursoId,conteudoId, conteudoItemId,conteudo ])
      break;
      default:
        break;
    }
   
  }

  goPerguntas(moduloId){
    //usar assim par fazer refresh no componente
    //this.route.routeReuseStrategy.shouldReuseRoute = () => false
    this.cursoService.getStateConteudoBeforeGo(moduloId, this.cursoId).subscribe(result=>{
      if(result.result == "true"){
        this.route.navigate(['area-aluno/cursos/respostas/', moduloId])
      }else{
        swal({
          title: 'Erro!',
          text: result.message,
          type: 'warning',
          showConfirmButton: false,
          timer: 5000
        })
      }
    })

    
  }

  obterConteudoStatus(){
    this.cusroServide.obterConteudoStatus().subscribe(conteudoStatus=>{
      console.log(conteudoStatus)
      this.conteudoFinalizado = conteudoStatus
    })

  }

  checkconteudoStatus(conteudoId, conteudoItemId){
    let status: boolean = false
    if(this.conteudoFinalizado.length > 0){
      let conteudoItem = this.conteudoFinalizado.find(conteudo => conteudo.capituloId === conteudoId.toString())
      if(conteudoItem != undefined && conteudoItem.conteudo.findIndex(x => x.conteudoItemId === conteudoItemId.toString()) >=0){
        status = true
      }else{
        status = false    
      }
    }
    return  status
  }

  checkConteudolFullStatus(capituloId, pConteudo){
    //check all content is ended.
    let status: number = 0
    if(this.conteudoFinalizado.length > 0){
      let conteudoItem = this.conteudoFinalizado.find(conteudo => conteudo.capituloId === capituloId.toString())
      if(conteudoItem != undefined){
        if(pConteudo.length === conteudoItem.conteudo.length){
          status = capituloId
        }else{
          status = 0    
        }
      }
    }
    return  status
  }

  checkRespostas(moduloId){
    let status: boolean = false    
    if(this.respostasRealisadas != undefined){
      if(this.respostasRealisadas.find(x => x.moduloId === moduloId)!=undefined){
        status = true
      }else{
        status = false
      }
    }
    return status
  }

 
  checkModuloLiberar(index){
    //check se o modulo anterior tem respostas
    let moduloAnterior = this.modulos[index-1]
    if (moduloAnterior != undefined){
      moduloAnterior = this.checkRespostas(moduloAnterior.id)
    }else{ 
      moduloAnterior = false    
    }

    return moduloAnterior
  }

  getPerguntas(moduloId){
    this.respostaService.getRespostas(moduloId).subscribe(result=>{
      console.log(result)
      let modulo = result[0]
      
    }, (error)=>{
      console.log("error =>", error)
    })
  }

  getPerguntasRespondidas(){
    let cursoId = this.loginService.user().cursoId
    this.respostaService.obterRespostaCursoModulo(cursoId).subscribe(result=>{
      console.log(result)
      this.respostasRealisadas = result      
    }, (error)=>{
      console.log("error =>", error)
    })
  }

  isToggle(event: any){
    let body = document.getElementsByTagName('body')[0];
    if(!body.classList.contains('is-collapsed')){
      body.classList.add('is-collapsed');
    }else{
      body.classList.remove('is-collapsed');
    }        
  }

  menuToggle(moduloIndex){
    let elementId = "modulo"+moduloIndex
    let perguntaId = "pergunta"+moduloIndex
    let arrowIconId = "arrowIcon"+moduloIndex
    let element = document.getElementById(elementId);
    let elementPergunta = document.getElementById(perguntaId);
    let elementarrowIcon = document.getElementById(arrowIconId);    
    if(!element.classList.contains('show-submenu')){
      element.classList.add('show-submenu');
      elementPergunta.classList.remove('hide-submenu');
      elementPergunta.classList.add('show-submenu');
      elementarrowIcon.classList.remove('fa-angle-right');
      elementarrowIcon.classList.add('fa-angle-down');      
    }else{
      element.classList.remove('show-submenu');
      elementPergunta.classList.remove('show-submenu');
      elementPergunta.classList.add('hide-submenu');
      
      elementarrowIcon.classList.add('fa-angle-right');
      elementarrowIcon.classList.remove('fa-angle-down');      
    }   
    
  }


  sortConteudo(conteudo){
  
   return  conteudo.sort((a, b) => parseFloat(a.ordem) - parseFloat(b.ordem));
  }

  setConteudos(conteudos){
    window.localStorage.setItem("conteudos", JSON.stringify(conteudos))
  }

  collapseSubMenu(collapseItem){
    let body = document.getElementsByName(collapseItem)[0];
    if(!body.classList.contains('collapse')){
      body.classList.add('collapse');
     
    }else{
      body.classList.remove('collapse');
    }        
  }

  changeRoute(url, conteudos, id) {
    this.setConteudos(conteudos)    
    this.route.navigate(['area-aluno/cursos/conteudos', id])
    this.conteudoActive = id
  }

  collapsedMenu(event, index, descricao){
    this.isCollapsed = "submenu"+index
    
    this.menuService.setDescriptionModulo(descricao)
    if(!event.target.parentElement.parentElement.classList.contains("collapsed")){
      event.target.parentElement.parentElement.classList.add("collapsed")
      event.target.parentElement.parentElement.setAttribute("aria-expanded", "true")
      
    }else{
      event.target.parentElement.parentElement.classList.remove("collapsed")
      event.target.parentElement.parentElement.setAttribute("aria-expanded", "false")
     this.isCollapsed =""
    }

  }

  getModulosRealizados(){
    this.modulos.forEach(element => {
      if(this.checkRespostas(element.id)){
        this.quantidadeModulosRespondidos = this.quantidadeModulosRespondidos + 1 
      }      
    });    
  }


  getCountModulos(){
    this.quantidadeTotalModulos = this.modulos != undefined ? this.modulos.length : 0    
  }

  setDataProgressBar(){
    let data = {total: this.quantidadeTotalModulos, quantidade: this.quantidadeModulosRespondidos}
    this.menuService.passValue(JSON.stringify(data))
  }

  

  
  
}
