import { Injectable, Injector } from '@angular/core'
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse} from '@angular/common/http'
import { Observable } from 'rxjs'

import { LoginService } from './login/login.service'

@Injectable()
export class AuthInterceptor implements HttpInterceptor{

    constructor(private injector: Injector){}
    intercept(request: HttpRequest<any>, next:HttpHandler): Observable<HttpEvent<any>>{

        const loginService = this.injector.get(LoginService)
       
        if(loginService.isLoggedIn()){
            const authRequest = request.clone({headers:request.headers.set('Authorization', `${loginService.getAccessToken()}`)})
            return next.handle(authRequest)
        } else {
            loginService.logout()
            return next.handle(request)
        }
    }
}