import { Component, OnInit } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router';
import { LoginService } from '../security/login/login.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  analiseCS: number
  analiseCAI: number 

  safetyConversations: any[] = []
  accidentReports: any[] = []
  p: any
  user: any
  curso:any
  descricao: any
  cargaHoraria: any
  constructor(private router: Router,
              private loginService: LoginService,
              private sanitizer: DomSanitizer) {

   }

  ngOnInit() {
    this.curso = this.loginService.user().nomeCurso
    this.descricao = this.loginService.user().descricaoCurso
    this.cargaHoraria = this.loginService.user().cargaHoraria
  }

  managementAcident(id:string){
    this.router.navigate(['dashboard/analisecomunicacao/gerenciamento',id]);
  }

  managementConcersation(id:string){
    this.router.navigate(['dashboard/analiseconversaseguranca/gerenciamento',id]);
  }

  getHtml(html){    
     return this.sanitizer.bypassSecurityTrustHtml(html)    
  }
}
