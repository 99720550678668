import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RespostaService } from './respostas.service';
import swal from 'sweetalert2';
import { BACKEND_FILE } from '../app.api';
import { LoginService } from '../security/login/login.service';

@Component({
  selector: 'app-respostas',
  templateUrl: './respostas.component.html',
  styleUrls: ['./respostas.component.scss']
})
export class RespostasComponent implements OnInit {

  @ViewChild('respostaCorreta') respostaCorreta;
  modulo: any
  currentTab = 0
  // cadastroForm: FormGroup
  moduloId: any
  listPerguntas: any
  perguntas: any = []
  perguntaResposta: any
  resposta: any
  foto: any
  perguntasResposdidas:  boolean = false
  existsPicture = false
  cursoId: any

  constructor(private fb: FormBuilder, 
            private router: ActivatedRoute,
            private route: Router,
            private respostaService: RespostaService,
            private loginService: LoginService) { 

    
    this.moduloId = this.router.snapshot.params['modulo']
    this.cursoId = this.loginService.user().cursoId
  }

  ngOnInit() {
  
    // this.perguntaResposta = this.cadastroForm.controls['resposta'] as FormArray
    this.showTab(this.currentTab)
    this.getPerguntas()
    
  }

  ngAfterViewInit(){

  } 


  showTab(n:any){
    const x = document.getElementsByClassName("tab")
    // x[n].style.display = "block";
  // ... and fix the Previous/Next buttons:
    // if (n == 0) {
    //   document.getElementById("prevBtn").style.display = "none";
    // } else {
    //   document.getElementById("prevBtn").style.display = "inline";
    // // }
    // if (n == (x.length - 1)) {
    //   document.getElementById("nextBtn").innerHTML = "Submeter";
    // } else {
    //   document.getElementById("nextBtn").innerHTML = "Next";
    // }
    // ... and run a function that displays the correct step indicator:
    this.fixStepIndicator(n)
  }

   nextPrev(n) {
    // This function will figure out which tab to display
    var x = document.getElementsByClassName("tab");
    // Exit the function if any field in the current tab is invalid:
    // if (n == 1 && !validateForm()) return false;
    // Hide the current tab:
    // x[this.currentTab].style.display = "none";
    // Increase or decrease the current tab by 1:
    this.currentTab += n;
    // if you have reached the end of the form... :
    if (this.currentTab >= x.length) {
      //...the form gets submitted:
      //document.getElementById("regForm").submit();
      return false;
    }
    // Otherwise, display the correct tab:
    this.showTab(this.currentTab);
  }

   fixStepIndicator(n) {
    // This function removes the "active" class of all steps...
    var i, x = document.getElementsByClassName("step");
    for (i = 0; i < x.length; i++) {
      x[i].className = x[i].className.replace(" active", "");
    }
    //... and adds the "active" class to the current step:
    // x[n].className += " active";
  }

  getPerguntas(){
    this.respostaService.getRespostas(this.moduloId).subscribe(result=>{
      console.log(result)
      this.modulo = result[0]
      this.listPerguntas = this.modulo.Perguntas

      //analisar se existe alguma pergunta respondida, como é um conjunto, se houver 1 resposta este item esta finalizado.
      this.checkRespostas(this.listPerguntas)


      this.listPerguntas.forEach(element => {
        for(let i = 0, length = element.questoes_items.length; i < length; i++){
          // this.perguntaResposta.push(this.fb.control(''))
          this.perguntas.push(element.questoes_items[i])
        }
      });

    }, (error)=>{
      console.log("error =>", error)
    })
  }

  getRespostaById(id): any {
     return this.perguntas.find(p => p.respostaId == id);
  }

  gravar(form){
    let data:any = {}
    data.answer = form
    data.cursoId = this.cursoId
    data.moduloId = parseInt(this.moduloId)
    this.respostaService.createAnswer(data).subscribe(answer=>{
      swal({
        title: 'Potencial Global',
        text: "Respostas gravadas com sucesso",
        type: 'success',
        showConfirmButton: false,
        timer: 3000
      })
      this.route.routeReuseStrategy.shouldReuseRoute = () => false
      this.route.navigate(['area-aluno'])
    }, (error)=>{
      swal({
        title: 'Potencial Global',
        text: "Não foi possivel gravar as respostas. Por favor tente mais tarde.",
        type: 'error',
        showConfirmButton: false,
        timer: 3000
      })
    
    })
  }

  retornarFoto(questionId){
    // let url = BACKEND_FILE + "/pergunta/" + questionId +'-0.png'
    // this.imageExists(url, (exists) =>{
    //   console.log('RESULT: url=' + url + ', exists=' + exists);
    //   if(exists){
    //     return BACKEND_FILE + "/pergunta/" + questionId +'-0.png'
    //   }{
    //     return null
    //   }
    // });
    return BACKEND_FILE + "/pergunta/" + questionId +'-0.png'
    
  }

  checkExistsPicture(event){
    console.log("evento =>", event)
    event.target.parentElement.style.display = "none"
    this.existsPicture = false
  }

  //Se a pergunta ja foi respondida e gravada. se exitir uma resposta gravada o conjunto ja foi respondido.
  checkRespostas(perguntas){
    let breakException = {}
    try{
      perguntas.forEach(element => {
        element.questoes_items.forEach(respostas => {
          if(respostas.respostas.length === 0){ 
            this.perguntasResposdidas = false
          }else{
            this.perguntasResposdidas = true
            throw breakException
          }
        });
      });
    }catch(e){
      if (e !== breakException) throw e;
    }
  }

  
  //pergunta respondida
  getStatusResposta(resposta){
    let statausResposta
    resposta.respostas.forEach(element => {
      
      if (!element){
        statausResposta  = false
      }else{
        statausResposta = true
      }   
    });

    return statausResposta
   
  }

  withoutImage(event){
    
  }

  imageExists(url, callback) {
    var img = new Image()
    img.onload = function() { callback(true); }
    img.onerror = function() { callback(false); }
    img.src = url;
  }

  // var imageUrl = 'http://www.google.com/images/srpr/nav_logo14.png';
  checkImageExists(imageUrl){
    this.imageExists(imageUrl, (exists) =>{
      console.log('RESULT: url=' + imageUrl + ', exists=' + exists);
    });
  } 

  checkCorrectItem(answer){
    return answer === 1 ? true : false 
  }
}//end class
