<div class="container" style="margin-top: 200px;">
  <div class="masonry-item col-md-4">
    <div class="col-md-12" style="vertical-align:baseline">
      <img src="../assets/img/LogosPG-01.png" style="height: 30px; padding-right: 15px;" alt="">
      <strong><label>Primeiro acesso</label></strong>
    </div>
    <div class="bgc-white p-20 bd shadow">
      <h5 class="c-grey-900">Informações iniciais</h5>
      <div class="mT-30">
        <form [formGroup]="cadastroForm" method="post">
          <div class="form-group has-feedback">
            <input type="text" formControlName="nome" name="nome" id="nome" class="form-control input_name" placeholder="Digite seu nome" required>
          </div>
          <div class="form-group has-feedback">
            <input type="text" formControlName="nif" name="nif" id="nif" class="form-control input_name" placeholder="Digite seu NIF" required>
          </div>
    
          <div class="form-group has-feedback" style="text-align: center">
              <button (click)="save(cadastroForm.value)" type="submit" class="btn btn-info"> Confirmar </button>&nbsp;
          </div>
        </form>
      </div>
    </div>
  </div>
</div>