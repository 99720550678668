
<div class="row" style="padding: 0px 10%;">
  
  <div class="col-12">
    <pdf-viewer [src]="pdfSrc" 
              [render-text]="false"
              [original-size]="false"              
              [autoresize]="false"
              [fit-to-page]="false"
              [show-all]="false"          
              [page]="page"
              (after-load-complete)="afterLoadComplete($event)"
              (page-rendered)="pageRendered()"
              style="display: block; height: 100%;"
              *ngIf="extFile === 'pdf'"
    ></pdf-viewer>    
    <ngx-doc-viewer *ngIf="extFile === 'pptx'" [url]="doc" viewer="google" style="width:100%;height:100vh;"></ngx-doc-viewer>
    
  </div>
 
  <div class="col">
    <div *ngIf="isLoaded" style="text-align: center;">
      <button  title="Retornar" class="btn btn-primary btn-sm" (click)="prevPage()" [disabled]="page === 1"   >
        <i class="fa fa-angle-left"></i> 
      </button>
      <span> {{ page }} / {{ totalPages }} </span>
      <button  title="Avançar" class="btn btn-primary btn-sm" (click)="nextPage()" [disabled]="page === totalPages">
        <i class="fa fa-angle-right"></i>
      </button> 
      <button title="Marcar como concluido" class="btn btn-primary btn-sm" (click)="concluirConteudo()" *ngIf="page === totalPages && !concluido ">
          <i class="fa fa-check-circle-o" aria-hidden="true"></i> 
      </button>
    </div>
  </div>
</div>
<!-- 
<div *ngIf="isLoaded" style="text-align: center;">
  <button  title="Retornar" class="btn btn-primary btn-sm" (click)="prevPage()" [disabled]="page === 1">
    <i class="fa fa-angle-left"></i> 
  </button>
  <span> {{ page }} / {{ totalPages }} </span>
  <button  title="Avançar" class="btn btn-primary btn-sm" (click)="nextPage()" [disabled]="page === totalPages">
    <i class="fa fa-angle-right"></i>
  </button> 
  <button title="Marcar como concluido" class="btn btn-primary btn-sm" (click)="concluirConteudo()" *ngIf="page === totalPages && !conluido ">
      <i class="fa fa-check-circle-o" aria-hidden="true"></i> 
  </button>
</div> -->


