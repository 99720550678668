<div class="container-flex bg-default" >

    <div class="bg-acesso">
      <div class="auth-container">
        <form [formGroup]="loginForm" method="post"> 
          <div class="form-group">
            <label for="token">Código de acesso</label>
            <input type="text" formControlName="token" name="token" id="token" class="form-control"  required>
          </div>
          <div class="form-group">
            <button (click)="login(loginForm.value)" type="submit" class="btn btn-outline-light"> Aceder </button>
          </div>
        </form>
      </div>
    </div>
    <div class="bg-barra">  
    </div>
    <img class="logo-login" src="../assets/img/LogosPG-01.png" alt="Potencial Global" >
</div>