import { NotFoundComponent } from './not-found/not-found.component';
import { Routes } from '@angular/router'

import { HomeComponent } from './home/home.component';

import { LoggedInGuard } from './security/loggedin.guard';
import { LoginComponent } from './security/login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormUserComponent } from './user/formuser/formuser.component';
import { UsuariosComponent } from './user/user.component';
import { ForgotpasswordComponent } from './security/forgotpassword/forgotpassword.component';
import { ChangepasswordComponent } from './security/changepassword/changepassword.component';
import { RedefinepasswordComponent } from './security/redefinepassword/redefinepassword.component';

import { CursoPdfComponent } from './curso/curso-pdf/curso-pdf.component';
import { AlunoComponent } from './security/aluno/aluno.component';
import { RespostasComponent } from './respostas/respostas.component';
import { VideoComponent } from './curso/conteudo/video/video.component';
import { ConteudoComponent } from './conteudo/conteudo.component';
import { ModuloDescircaoComponent } from './modulo-descircao/modulo-descircao.component';
import { ForumComponent } from './forum/forum.component';
import { CommentListComponent } from './forum/comment-list/comment-list.component';
import { IframeComponent } from './curso/conteudo/iframe/iframe.component';

export const ROUTES: Routes = [
    {path: '', component: LoginComponent},
    {path: 'login', component: LoginComponent},
    {path: 'area-aluno', component: DashboardComponent,
        children: [
            {path: '', component: HomeComponent},
            {path: 'aluno/cadastro/:token', component: AlunoComponent},
            {path: 'cursos/conteudo/:cursoId/:conteudoId/:conteudoItemId/:conteudo', component: CursoPdfComponent, canActivate: [LoggedInGuard], canLoad:[LoggedInGuard], runGuardsAndResolvers:'always'},
            {path: 'cursos/conteudo/video/:cursoId/:conteudoId/:conteudoItemId/:conteudo', component: VideoComponent, canActivate: [LoggedInGuard], canLoad:[LoggedInGuard], runGuardsAndResolvers:'always'},
            {path: 'cursos/conteudo-embedded/:cursoId/:conteudoId/:conteudoItemId', component: IframeComponent, canActivate: [LoggedInGuard], canLoad:[LoggedInGuard], runGuardsAndResolvers:'always'},
            {path: 'cursos/respostas/:modulo', component: RespostasComponent, canActivate: [LoggedInGuard], canLoad:[LoggedInGuard]},
            {path: 'cursos/conteudos/:conteudoId', component: ConteudoComponent, canActivate: [LoggedInGuard], canLoad:[LoggedInGuard]},
            {path: 'cursos/modulos', component: ModuloDescircaoComponent, canActivate: [LoggedInGuard], canLoad:[LoggedInGuard]},
            {path: 'cursos/forum', component: ForumComponent, canActivate: [LoggedInGuard], canLoad:[LoggedInGuard]},
            {path: 'cursos/forum/comments-list/:discussion/:subject', component: CommentListComponent, canActivate: [LoggedInGuard], canLoad:[LoggedInGuard]},
            {path: '**', component: NotFoundComponent}
        ]
    }
]