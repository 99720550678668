import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http'
import { UserService } from '../user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../user.model';
import { POTENCIAL_API } from 'src/app/app.api';
import swal from 'sweetalert2';

@Component({
  selector: 'app-formuser',
  templateUrl: './formuser.component.html'
})
export class FormUserComponent implements OnInit {

  cadastroForm: FormGroup
  cadastro: User
  validadorEmail:boolean = false
  liberacao:boolean = false
  id: string

  constructor(private cadastroService: UserService,
              private http: HttpClient,
              private router: Router,
              private fb: FormBuilder,
              private activatedRoute: ActivatedRoute) { 
                this.id = this.activatedRoute.snapshot.params['id']  
              }

  ngOnInit() {

    this.cadastroForm = this.fb.group({
      name: this.fb.control(''),
      email: this.fb.control(''),
      tipo: this.fb.control('')
    })
    this.getUser(this.id)
  }

  getUser(id: string){
    if(id) {
      this.cadastroService.view(id).subscribe(retorno => {
        this.cadastroForm.patchValue({
          name: retorno.name,
          email: retorno.email,
          tipo: retorno.tipo
        })
      })
    } else {
      this.cadastroForm.patchValue({
        tipo: 'Administrador'
      })
    }
  }

  gravar(cadastro: User){
    let textoErro = ""

    if(cadastro.name==''){
        textoErro= 'Informe um nome completo!'
    }else if(cadastro.email==''){
        textoErro= 'Informe um email!'
    }

    if(textoErro!=''){
      swal({
        title: 'Gypsum',
        text: textoErro,
        type: 'error',
        showConfirmButton: false,
        timer: 3000
      })
    } else {
      if(this.id== undefined){
        this.save(cadastro)
      } else {
        this.update(cadastro)
      }
    }    
  }

  save(cadastro: User){
    return this.http.post<User>(`${POTENCIAL_API}/v2/api/users`, cadastro)
    .subscribe(result => {

      if(result["message"]=="Validação"){
        swal({
          title: 'Gypsum',
          text: 'A senha deve conter ao menos 6 caracteres, um número, um caracter especial, uma letra maiúscula e uma minúscula.',
          type: 'error',
          showConfirmButton: false,
          timer: 6000
        })  
      } else {
        swal({
          title: 'Gypsum',
          text: 'Cadastrado realizado com sucesso!',
          type: 'success',
          showConfirmButton: false,
          timer: 1500
        })
        setTimeout( () => {
          swal.close()
          this.router.navigate(['/dashboard/usuarios'])

        }, 2000 );
      }

    },
    (erro)=>{

      swal({
        title: 'Erro!',
        text: erro.error.errors,
        type: 'warning',
        showConfirmButton: false,
        timer: 2000
      })
    })  
  } 

  update(cadastro: User){
    this.cadastroService.update(this.id, cadastro).subscribe((result)=>{
      swal({
        title: 'Gypsum',
        text: 'Registro alterado com sucesso!',
        type: 'success',
        showConfirmButton: false,
        timer: 1500
      })
      setTimeout( () => {
        this.router.navigate(['/dashboard/usuarios'])
        swal.close()        
      }, 2000 );
    })    
  }

  validaEmail(cadastro: User){

    if(cadastro.email!=''){
      
      this.cadastroService.checarEmail(cadastro.email).subscribe(res=>{
        res==true?this.validadorEmail=true:this.validadorEmail=false
      },error=>{
        this.validadorEmail = false
      })
    }
  }
}