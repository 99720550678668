
<div class="col my-2">
    <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal" (click)="openModal($event)" title="registrar uma nova duvida.">
        Novo
    </button>
</div>

<div class="col">
  <ng-container *ngFor="let discussionFixo of allDiscussionsFixo">
    <div class="card my-3">
        <a [routerLink]="['/area-aluno/cursos/forum/comments-list', discussionFixo._id, discussionFixo.subject]">
          <h5 class="card-header">{{discussionFixo.subject}} - Autor: {{discussionFixo.author[0].name}}</h5>          
        </a>        
        <div class="card-body">
            <div [innerHTML]="discussionFixo.text"></div>
            
            <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal" (click)="openModalComments($event, discussionFixo._id,  discussionFixo.subject)">
                Responder
            </button>
        </div>
        <div class="card-footer text-muted">
          <div class="row">
            <div class="col ml-2" >
              <span><i class="fa fa-calendar" aria-hidden="true"></i></span>&nbsp;
              <span>{{getFormatDate(discussionFixo.createdAt)}}</span>
            </div>
            <div class="col" title="Total de comentarios">
              <span><i class="fa fa-sticky-note-o" aria-hidden="true"></i></span>&nbsp;
              <span>{{ !discussionFixo.comments_docs ? 0 : discussionFixo.comments_docs.length}}</span>
          </div>
          </div>
        </div>
    </div>
  </ng-container>
</div>
<div class="col">
  <ng-container *ngFor="let discussion of allDiscussions">
    <div class="card my-3">
        <a [routerLink]="['/area-aluno/cursos/forum/comments-list', discussion._id, discussion.subject]">
          <h5 class="card-header">{{discussion.subject}} - Autor: {{discussion.author[0].name}}</h5>          
        </a>        
        <div class="card-body">
            <div [innerHTML]="discussion.text"></div>
            
            <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal" (click)="openModalComments($event, discussion._id,  discussion.subject)">
                Responder
            </button>
        </div>
        <div class="card-footer text-muted">
          <div class="row">
            <div class="col ml-2" >
              <span><i class="fa fa-calendar" aria-hidden="true"></i></span>&nbsp;
              <span>{{getFormatDate(discussion.createdAt)}}</span>
            </div>
            <div class="col" title="Total de comentarios">
              <span><i class="fa fa-sticky-note-o" aria-hidden="true"></i></span>&nbsp;
              <span>{{ !discussion.comments_docs ? 0 : discussion.comments_docs.length}}</span>
          </div>
          </div>
        </div>
    </div>
  </ng-container>
</div>
<!-- Button trigger modal -->

  
  <!-- Modal -->
  <div class="modal fade" id="modalNovoRegistro" #modalForumNovo tabindex="-1" role="dialog" aria-labelledby="mobalNovoRegistro" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="mobalNovoRegistro">Coloque sua dúvida?</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal(modalForumNovo)">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="input-group my-2">
             <Label>Assunto</Label>
             <input type="text" class="input-group" [(ngModel)]="model.subject">
          </div>
          <ckeditor id="ckEditor" [editor]="Editor" 
          [config]="editorConfig"
          data="<p>Hello, world!</p>" 
          [(ngModel)]="model.text"></ckeditor>          
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal(modalForumNovo)">Desistir</button>
          <button type="button" class="btn btn-primary" (click)="saveDiscussion(model)">Guardar</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="modalNovoComments" #modalCommentsNovo tabindex="-1" role="dialog" aria-labelledby="modalNovoComments" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalNovoComments">Responder um duvida.</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal(modalCommentsNovo)">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="input-group my-2">
             <Label>{{subject}}</Label>             
          </div>
          <ckeditor [editor]="Editor" data="<p>Hello, world!</p>" [(ngModel)]="model.text"></ckeditor>          
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal(modalCommentsNovo)">Desistir</button>
          <button type="button" class="btn btn-primary" (click)="saveComments(model)">Guardar</button>
        </div>
      </div>
    </div>
  </div>