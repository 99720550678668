import { Component, OnInit, EventEmitter} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup

  constructor(private fb: FormBuilder, 
              private loginService: LoginService,
              private router: Router,
              ) { }

  ngOnInit() {
    this.loginForm = this.fb.group({
      token: this.fb.control('', [Validators.required]),
      
    })
  }

  login(token) {
    this.loginService.login(token.token).subscribe(result => {
      if(!result.nif){
        this.loginService.getIsLoggedEmitter().emit("isLogged")
        window.localStorage.setItem("token", token.token)
        window.localStorage.setItem('user', JSON.stringify(result))
        this.loginService.setLogIdle("LOGIN").subscribe(()=>{})
        this.router.navigate(['/area-aluno/aluno/cadastro',token.token])
      }else{
        this.loginService.getIsLoggedEmitter().emit("isLogged")
        window.localStorage.setItem("token", token.token)
        window.localStorage.setItem('user', JSON.stringify(result))
        this.loginService.setLogIdle("LOGIN").subscribe(()=>{})
        this.router.navigate(['/area-aluno/cursos/forum'])
      }
     
    }, (error) => {
      Swal({
        // heightAuto: false,
        title: 'Erro!',
        text: 'Token inválido.',
        type: 'warning',
        showConfirmButton: true,
        timer: 2000
      })
    })
    
  }

}
