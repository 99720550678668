<div class="container">
    <div class="masonry-item col-md-4">
      <div class="bgc-white p-20 bd shadow">
        <h5 class="c-grey-900">Alterar Senha</h5>
        <div class="mT-30">
          <form [formGroup]="changePasswordForm">
  
            <div class="form-group">
                <label for="password">Senha Atual</label>
                <input type="password" class="form-control" id="password" formControlName="password">
            </div>
            
            <div class="form-group">
                <label for="new_password">Nova Senha</label>
                <input type="password" class="form-control" id="new_password" formControlName="new_password">
            </div>

            <div class="form-group">
                <label for="confirm_password">Repetir Nova Senha</label>
                <input type="password" class="form-control" id="confirm_password" formControlName="confirm_password">
            </div>
      
            <div class="form-group has-feedback" style="text-align: center">
                <button (click)="updatePassword(changePasswordForm.value)" type="submit" class="btn btn-info btn-sm"> Alterar senha </button>&nbsp;
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  