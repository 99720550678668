import { Component, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CursoService } from '../../curso.service';
import swal from 'sweetalert2';


@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss']
})
export class IframeComponent implements OnInit {

  conteudo: any
  conteudoIframe: any
  conteudoId: any
  isLoaded:  true
  concluido: boolean = false
  conteudoItemId: any
  concluidoConteudoItem: any
  materialStatus:any
  
  

  constructor(private router: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private cusroServide: CursoService,
    private route: Router) { 

    // this.conteudo       = this.router.snapshot.params['conteudo']
    this.conteudoId     = this.router.snapshot.params['conteudoId']
    this.conteudoItemId = this.router.snapshot.params['conteudoItemId']
    

  }

  ngOnInit() {
    this.obterConteudoStatus()
    this.loadIframe()
  }

  concluirConteudo(){
    this.conteudoId = this.router.snapshot.params['conteudoId']
    let data        = {capituloId: this.conteudoId, conteudo:[{dataConclusao: new Date(), conteudoItemId: this.conteudoItemId}]}

    this.cusroServide.concluirModulo(this.conteudoId, data).subscribe(result=>{
      swal({
        title: 'Potencial Global',
        text: "Conclusão do conteúdo realizado",
        type: 'success',
        showConfirmButton: false,
        timer: 3000
      })
      this.obterConteudoStatus()
      this.route.navigate(['area-aluno']);
      
    },(error)=>{
      swal({
        title: 'Potencial Global',
        text: 'Não foi possivel conlcui no momento, por fafor tente mais tarde.',
        type: 'error',
        showConfirmButton: false,
        timer: 3000
      })
    })

  }

  obterConteudoStatus(){
    this.cusroServide.obterConteudoStatus().subscribe(conteudoStatus=>{
      this.checkConteudoStatus(conteudoStatus)
      //
      this.concluidoConteudoItem = conteudoStatus
      this.concluido = this.checkMaterialStatus(this.conteudoId, this.conteudoItemId)
      // this.checkMaterialStatus(conteudoStatus.conteudo)
      console.log(conteudoStatus)
      // this.statusConteudo.emit(conteudoStatus)
    })

  }

  checkConteudoStatus(respostas){
    if(respostas.findIndex(x => x.capituloId === this.conteudoId) >=0){
      this.concluido = true
    }else{
      this.concluido = false
    }
    
  }

  //checar se o material foi finalizado.
  checkMaterialStatus(capituloId, conteudoItemId){
    let status: boolean = false
    let conteudoItem    = this.concluidoConteudoItem.find(conteudo => conteudo.capituloId === capituloId)
    if(conteudoItem != undefined && conteudoItem.conteudo.findIndex(x => x.conteudoItemId === conteudoItemId) >=0){
      status = true
    }else{
      status = false    
    }
    return  status
  }

  loadIframe(){
      let content = this.cusroServide.getConteudoIframe()
    this.conteudoIframe = this.sanitizer.bypassSecurityTrustHtml(content.replace(/\"/g,"'"))
    
  }



}