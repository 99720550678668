
<!-- <app-menu (statusConteudo)="chengeStatus()"></app-menu> -->  
<nav class="navbar navbar-expand-md fixed-top navbar-white bg-white">
  <app-header style="display: flex;"></app-header>
</nav>
  
  <main role="main" class="container-fluid">
    <div class="nav-scroller bg-color-titleBar box-shadow">
      <nav class="nav nav-underline formacao-name">
          <div class="my-2 my-lg-0" style="padding: 30px;">
            <span>{{tituloCurso}}</span> 
          </div>
          <!-- <div class="my-2 my-lg-0" style="padding: 10px;" >
                <div class="formacao-customer-brand">
                    <img src="assets/image/mainpage.logox_.sharp_.png" width="auto" height="50px" alt="">
                </div>
          </div> -->
        
      </nav>
    </div>

    <div class="row p-3 my-3">

      <div class="col-sm-3 menu-responsive">
        
          <app-menu ></app-menu>
        </div>
      
      <div class="col-sm-8">
        <router-outlet></router-outlet>    
      </div>
    </div>
    
  
    <!-- <div class="sidebar-rigth">
      <div class="button-menu">
        <button type="button" id="sidebarCollapse" class="btn btn-primary" (click)="collapsedMenu($event)">
        </button>
      </div>
      <div class="menu-rigth">
        <ul class="narbar-nav mr-auto my-2" style="list-style: none;">
          <li class="nav-item"><a [routerLink]="['cursos/forum']">Duvidas?</a></li>
          <li class="nav-item"><a href="">Fale com o formador!</a></li>
        </ul>        
      </div>
    </div> -->
  
    
  </main>
  <!-- <app-footer></app-footer> -->

