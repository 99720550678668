import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http'
import { POTENCIAL_API, BACKEND_FILE } from '../app.api';
import { Observable } from "rxjs";




@Injectable()

export class CursoService{

  CurrentContentData = {url:"", page: 0}
  conteudoIframe: string

  constructor(private http: HttpClient){}
  
  getSafeStart(id:string): Observable<any> {
    return this.http.get<any>(`${POTENCIAL_API}/v2/api/human-reports/get/${id}`)
  }
  
  listModulos(cursoId): Observable<any[]> {
    return this.http.get<any[]>(`${POTENCIAL_API}/api/modulo/${cursoId}`)
  }

  getConteudo(conteudo): Observable<any[]> {
    return this.http.get<any[]>(`${POTENCIAL_API}/api/capitulo/conteudo/${conteudo}`, {responseType: 'arraybuffer' as 'json'})
  }
  
  update(_id: string, body: any[]): Observable<any>{
    return this.http.put<any>(`${POTENCIAL_API}/v2/api/human-reports/${_id}`, body)
  }

  concluirModulo(_id: string, body: any): Observable<any>{
    let cursoId = JSON.parse(window.localStorage.getItem("user")).cursoId
    return this.http.post<any>(`${POTENCIAL_API}/api/alunos/conclusao/conteudo/${cursoId}/${_id}`, body)
  }

  obterConteudoStatus(): Observable<any>{
    let cursoId = JSON.parse(window.localStorage.getItem("user")).cursoId
    return this.http.get<any>(`${POTENCIAL_API}/api/alunos/status/conteudo/${cursoId}`)
  }

  setCurrentContent(url: string, page: number){
    this.CurrentContentData.page = page
    this.CurrentContentData.url = url
    window.localStorage.setItem("currentContent", JSON.stringify(this.CurrentContentData))
  }

  getCurrentContent(){
    return !JSON.parse(window.localStorage.getItem("currentContent")) ? {}: JSON.parse(window.localStorage.getItem("currentContent"))
  }


  setConteudoIframe(item){
      this.conteudoIframe = item
  }

  getConteudoIframe(){
      return this.conteudoIframe
  }

  getStateConteudoBeforeGo(capituloId, cursoId):Observable<any>{
    return this.http.get<any[]>(`${POTENCIAL_API}/api/alunos/conclusao/conteudos/status/${cursoId}/${capituloId}`)
  }

}  