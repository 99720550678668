import { Component, OnInit } from '@angular/core';
import { LoginService } from '../security/login/login.service';
import { Router } from '@angular/router';
import { User } from '../security/login/user.model';
import { BACKEND_FILE } from '../app.api';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  
  loggedIn: boolean  
  logoEmpresa:string 
  logo: string
  nomeCurso: String
  src: any

  constructor( private loginService: LoginService, route: Router) {
    this.loggedIn = this.isloggedin()
    this.logo = this.loginService.user().logo
    this.nomeCurso = window.localStorage.getItem("nomeCurso")
   }
  
  ngOnInit() {
    this.logoEmpresa = !this.logo  ? '..assets/img/formandos.png' :`${BACKEND_FILE}/${this.logo}`
    console.log("Version: 2020/03/30")
  }

  isloggedin(){
    return this.loginService.isLoggedIn()
  }

  isToggle(event: any){
    let body = document.getElementsByName('navbarCollapse')[0];
    if(!body.classList.contains('open')){
      body.classList.add('open');
    }else{
      body.classList.remove('open');
    }        
  }

  usuario(): User {
    return this.loginService.user()
  }

  logout() {
    this.loginService.setLogIdle("USER").subscribe(()=>{})
    this.loginService.logout()
    
  }  
  updateUrl($event){
    //this.src = this.logoEmpresa
    this.src = '..assets/img/formandos.png'
  }
}