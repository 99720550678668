import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UpdatePassword } from 'src/app/user/user.model';
import { UserService } from 'src/app/user/user.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html'
})
export class ChangepasswordComponent implements OnInit {

  changePasswordForm: FormGroup

  constructor(private userService: UserService,
              private http:HttpClient,
              private router: Router,
              private fb: FormBuilder) { }

  ngOnInit() {
    this.changePasswordForm = this.fb.group({
      password: this.fb.control(''),
      new_password: this.fb.control(''),
      confirm_password: this.fb.control('')
    })
  }

  updatePassword(updatePassword: UpdatePassword){

    this.userService.updatePassword(updatePassword)
    .subscribe(res => {
      console.log(res.error)
      if(res.error == undefined){
        swal({
          title: 'Gypsum',
          text: 'Senha alterada!',
          type: 'success',
          showConfirmButton: false,
          timer: 1500
        })
        setTimeout( () => {
          swal.close()
          this.router.navigate(['/home'])
        }, 2000 );
      } else {
        swal({
          title: 'Gypsum',
          text: res.error,
          type: 'error',
          showConfirmButton: false,
          timer: 1500
        })
      }

    })    
  }  
}