

  <a class="navbar-brand" href="#">
    <img alt="" class="header-logo-image image-brand"  src="../assets/img/LogosPG-01.png" >
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse"  data-target="#navbarCollapse" (click)="isToggle($event)">
    <span class="fa fa-bars" aria-hidden="true"></span>
  </button>
  <div class="box-brand2">
    <div class="box-brand2-internal">
       
        <div class="formacao-customer-brand" style="margin: 0 auto; text-align: center;">
          <img alt="" class="header-logo-image image-brand" [src]="logoEmpresa" (error)="updateUrl($event)">
        </div>
    
    </div>
  </div>
  
  <div class="navbar-collapse offcanvas-collapse open" name ="navbarCollapse" id="navbarCollapse" >
    <div class="row">
      <div class="my-2 col-sm-12">
        <div class="box-brand2 avatar-logo-image">
          <div class="box-brand2-internal">
             
              <div class="formacao-customer-brand" style="margin: 0 auto; text-align: center;">
                <img alt="" class="header-logo-image image-brand" [src]="logoEmpresa" (error)="updateUrl($event)">
              </div>
          
          </div>
        </div>
        <div class=" my-2 col-sm-12 avatar-mobile">        
            <img alt="" class="avatar-logo-image image-brand"  src="../assets/img/avatar.png" >           
            <span class="ml-2"> Olá, <strong>{{usuario()?.nome}}</strong>.</span>
            <a class="ml-1" href="javascript:void(0);" (click)="logout()">               
                Sair
            </a>
        </div>
        <div class="col-sm-12">
          <div class="my-2 my-lg-0">  
            <app-menu class="menu-mobile"></app-menu>
          </div>
        </div>
      </div>
    </div>
    



<!-- <div class="header navbar">
  <div class="header-container">
    <ul class="nav-left">
      <li>
          <a id='sidebar-toggle' class="sidebar-toggle" (click)="isToggle($event)" href="javascript:void(0);">
              <i class="fa fa-bars"></i>
            </a>
      </li>
    </ul> 
    
    <div class="header-logo">
        <div class="row">
            <img alt="" class="header-logo-image"  src="../assets/img/LogosPG-01.png">                            
            <div class="header-viertical-divider"></div>
            <img alt="" class="header-logo-image"  [src]="logoEmpresa" (error)="updateUrl($event)">                            
            <div style="margin-left: 15px;margin-top: 21px;" class="col mobbile-title-curso"><strong>{{nomeCurso}}</strong></div>
            <ul class="nav-right">
              <li class="dropdown">
                <a class="dropdown-toggle no-after peers fxw-nw ai-c lh-1" data-toggle="dropdown" href="javascript:void(0);" aria-expanded="false">
                  <div class="peer">
                    <span class="fsz-sm c-grey-900" style="padding: 0px 10px;">
                      Olá, <strong>{{usuario()?.nome}}</strong>
                    </span>
                  </div>
              </a>
                <ul class="dropdown-menu fsz-sm">
                  <li class="divider" role="separator"></li>
                  <li>
                    <a class="d-b td-n pY-5 bgcH-grey-100 c-grey-700" (click)="logout()">
                      <i class="fa fa-power-off mR-10"></i>
                      <span>Logout</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
        </div>        
    </div>
  </div>
</div> -->