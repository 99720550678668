import { Component, OnInit, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { User, FiltroUsuario } from './user.model';
import { BrowserModule } from '@angular/platform-browser';
import swal from 'sweetalert2';


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html'
})
export class UsuariosComponent implements OnInit {

  usuarios: User[]
  filter: User = new User()
  filtroUsuario: FiltroUsuario
  p: any

  constructor(public usuarioService:UserService,
              private router: Router) { }

  ngOnInit() {
    this.load()
  }

  delete(id) {

    swal({
      title: 'Gypsum',
      text: 'Deseja realmente excluir esse usuário?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar"
    })
    .then(willDelete => {
      if (willDelete.value) {

        this.usuarioService.destroy(id).subscribe(result=>{
          swal({
            title: 'Gypsum',
            text: 'Usuário excluído com sucesso!',
            type: 'success',
            showConfirmButton: false,
            timer: 1500
          })
          setTimeout( () => {
            this.load()
            swal.close()        
          }, 2000 );      
        })

      }
    });        
  }

  load(){

    swal({
      title: 'Carregando dados'
    });
    swal.showLoading();

    this.usuarioService.usuarios()
    .subscribe(usuarios => {
      this.usuarios = usuarios
      swal.close()})
  }

  update(id: number){
    this.router.navigate(['/dashboard/usuarios/cadastro',id]);
  }
}