import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http'
import { POTENCIAL_API, BACKEND_FILE } from '../app.api';
import { Observable } from "rxjs";


@Injectable()

export class ForumService{

  constructor(private http: HttpClient){}
  
  createDiscussion(data): Observable<any> {
    return this.http.post<any>(`${POTENCIAL_API}/api/forum/discussion/create`, data)
  }
  
  createComments(data): Observable<any> {
    return this.http.post<any>(`${POTENCIAL_API}/api/forum/comments/${data.discussionId}`, data)
  }

  getAllDiscussion(): Observable<any> {
    return this.http.get<any>(`${POTENCIAL_API}/api/forum/discussions`)
  }

  getAllComments(discussionId): Observable<any> {
    return this.http.get<any>(`${POTENCIAL_API}/api/forum/allComments/${discussionId}`)
  }

  getAllDiscussionFixo(): Observable<any> {
    return this.http.get<any>(`${POTENCIAL_API}/api/forum/fixo/discussions`)
  }

}  