import {Injectable, EventEmitter} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'
import {filter} from 'rxjs/operators'

import {User} from "./user.model"
import {POTENCIAL_API} from '../../app.api'
import {NavigationEnd, Router} from "@angular/router";

@Injectable()
export class LoginService {
  isLogged: EventEmitter<string> = new EventEmitter();
  lastUrl: string

  constructor(private http: HttpClient, private router: Router) {
    this.router.events
        .pipe(filter(e => e instanceof NavigationEnd))
        .subscribe((e: NavigationEnd) => this.lastUrl = e.url)
  }

  getIsLoggedEmitter() {
    return this.isLogged;
  }

  //Identificar se o usuario esta logado
  isLoggedIn(): boolean {
    let user: User = localStorage.getItem("user")?JSON.parse(localStorage.getItem("user")):undefined

    if (user){
      this.isLogged.emit("isLogged");
    } else {
      this.isLogged.emit("notLogged");
    }
    return user !== undefined
  }

  isCadastro(): boolean{
    let user: User = localStorage.getItem("user")?JSON.parse(localStorage.getItem("user")):undefined
    return user.nif !== null
  }

  user(): User {
    return window.localStorage.getItem("user")?JSON.parse(window.localStorage.getItem("user")):''
  }

  getToken() {
    return window.localStorage.getItem("token")?window.localStorage.getItem("token"):''
  }

  getAccessToken(){
    let user: User = window.localStorage.getItem("user")?JSON.parse(window.localStorage.getItem("user")):''
    return user.accessToken
  }

  login(token: string): Observable<User> {
    let user: any = {token: token}
    return this.http.post<User>(`${POTENCIAL_API}/apiO/aluno/login`, user)
  }

  handleLogin(path: string = this.lastUrl) {
    this.router.navigate(['/home', btoa(path)])
  }

  handleCadastro(path: string = this.lastUrl) {
    this.router.navigate([`/area-aluno/${path}`])
  }

  logout() {
    window.localStorage.removeItem('user')    
    this.isLogged.emit("notLogged");
    this.router.navigate(['login'])
  }

  requestNewPassword(login: string) {
    return this.http.post(`${POTENCIAL_API}/auth/new-password`, null, {params: {login: login}})
  }


  setLogIdle(type){
    return this.http.post(`${POTENCIAL_API}/api/logs/idle/create`, {type: type})
  }
}