import { Component, HostListener } from '@angular/core';
import { LoginService } from './security/login/login.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'Administração - Gypsum';
  telaCheia: boolean = false

  

  @HostListener('window:unload', [ '$event' ])
  unloadHandler(event) {
    // this.loginService

    this.loginService.setLogIdle('CLOSER-BROWSER').subscribe(()=>{})
    window.localStorage.removeItem("user")
    
  }

  @HostListener('window:beforeunload', [ '$event' ])
  beforeUnloadHandler(event) {
    this.route.navigate(['/login'])
  }


  

  loggedIn: boolean
  constructor(private loginService: LoginService, private route: Router){
    this.loggedIn = this.isloggedin()

    
    
  }

  

  isloggedin(){
    return this.loginService.isLoggedIn()
  }

 
}
