import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ForumService } from '../forum.service';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert2';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.scss']
})
export class CommentListComponent implements OnInit {

  comments: any =[]
  discussionId: any
  subject: any = ""
  public Editor = ClassicEditor;
  public model: any = {text:""}
  public modelComments: any = {text:"", discussionId:""}

  @ViewChild('modalCommentsNovo', {read: ElementRef}) modalCommentsNovo: ElementRef;
  
  constructor(private forumService: ForumService,
    private route: ActivatedRoute,
    private router: Router
    ) { 
    this.discussionId = this.route.snapshot.params['discussion']
    this.subject = this.route.snapshot.params['subject']
  }

  ngOnInit(): void {
    this.getAllComments(this.discussionId)
  }

  getAllComments(discussionId){
    swal.showLoading();
    this.forumService.getAllComments(discussionId).subscribe(result=>{
      this.comments = result
      
      swal.close()
    }, (error)=> {
      swal.close()
    })
  }

  goBack(){
    this.router.navigate(['area-aluno/cursos/forum'])
  }

  saveComments(model){
    let data = model
    data.discussionId = this.discussionId    
    this.forumService.createComments(data).subscribe(result=>{
      swal({
        title: 'Potencial Global',
        text: "Duvida registrada com sucesso.",
        type: 'success',
        showConfirmButton: false,
        timer: 3000
      })
      this.modalCommentsNovo.nativeElement.setAttribute("style", "display: none;")
      this.modalCommentsNovo.nativeElement.classList.remove("show")
      this.getAllComments(this.discussionId)
    }, (error)=>{
      swal({
        title: 'Potencial Global',
        text: "Não foi possivel gravar o comentário. Por favor tente mais tarde.",
        type: 'error',
        showConfirmButton: false,
        timer: 3000
      })
      this.modalCommentsNovo.nativeElement.setAttribute("style", "display: none;")
      this.modalCommentsNovo.nativeElement.classList.remove("show")
    })

  }

  openModalComments(event){
  
    if(!this.modalCommentsNovo.nativeElement.classList.contains("show")){
      this.modalCommentsNovo.nativeElement.setAttribute("style", "display: block;")
      this.modalCommentsNovo.nativeElement.classList.add("show")
    }else{
      this.modalCommentsNovo.nativeElement.setAttribute("style", "display: none;")
      this.modalCommentsNovo.nativeElement.classList.remove("show")
    }

  }

  closeModal(event){
    event.setAttribute("style", "display: none;")
    event.classList.remove("show")
  }

}
