import { Component, OnInit, Input } from '@angular/core';
import { MenuService } from '../menu/menu.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

  width: any
  @Input() total: any // total de itens
  @Input() quantidade: any //quantidade de itens realizados
  @Input() progressText: any //texto da progressão.

  constructor(private menuService: MenuService) { }

  ngOnInit(): void {
    console.log(this.total)
    this.menuService.jsonDataProgressBar.subscribe(data =>{
      let jsonData = JSON.parse(data)
      this.width = (jsonData.quantidade/jsonData.total)*100
      let percent = parseInt(jsonData.quantidade)/parseInt(jsonData.total)*100
      this.progressText = "Seu progresso 0 - " + parseInt(percent.toString()) + " %"
    }, (errors)=>{
      console.log(errors)
    })

    

  }

}
