<div class="container" style="margin-top: 200px;">
  <div class="masonry-item col-md-4">
    <div class="col-md-12" style="vertical-align:baseline">
      <img src="assets/img/logo.png" alt="">
      <strong><label>Sistema de controle de incidentes</label></strong>
    </div>
    <div class="bgc-white p-20 bd shadow">
      <h5 class="c-grey-900">Redefinir Senha</h5>
      <div class="mT-30">
        <form [formGroup]="redefinirForm">

          <div class="form-group has-feedback">
            <input type="password" class="form-control" formControlName="newpassword" placeholder="Nova senha">
          </div>
    
          <div class="form-group has-feedback">
            <input type="password" class="form-control" formControlName="confirmpassword" placeholder="Repetir nova senha">
          </div>
    
          <div class="form-group has-feedback" style="text-align: center">
            <button (click)="updatePassword(redefinirForm.value)" class="btn btn-info"> Redefinir </button>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>