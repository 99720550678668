
  <div type="course" class="sc-1ocq26b-0 jWWTBn eiqmqe-0 kPvqVG">
    <div class="fbub77-1 rheeF tabs-sections">
      <div data-cy="overview-tab" class="sc-11guzn9-3 kvkemJ fbub77-0 jZdhyb tabs-section tabs-section-overview tabs-section-active" content="[object Object]">
        <div class="sc-11guzn9-2 jWRTEJ"><span class="sc-11guzn9-1 boZhYw">Descrição</span>
        </div>
      </div>
    </div>
    <div class="sc-1ocq26b-1 ghAHqt tab-content">
      <div class="sc-1x1cbrk-0 XeseP sc-1ocq26b-4 jHzHcj">
        <div class="sc-1ocq26b-2 dSyYva">
          <div class="sc-12owa29-0 cCNyEY">
            <div class="sc-12owa29-2 dleIWI">
              <div class="sc-12owa29-1 sc-12owa29-5 lirStl">
                
                <div class="sc-12owa29-3 IIETU">
                  <strong>Duração</strong>
                  <span class="sc-1gwe6g6-0 kdVegY">{{cargaHoraria}}h</span>
                </div>
                
            </div>
          </div>
          <div class="cf2dg3-0 fTHriC"></div>
          <div name="entityDescription">
            <div class="sc-12owa29-1 sc-12owa29-6 kBbZhu">
              <div class="sc-12owa29-7 kKidrc">
                <h3 class="t3v5br-0 sc-12owa29-4 cNatEm my-3">Descrição</h3>
                
                <div class="sc-190ne8x-0 dtBUTI">
                  <div class="sc-190ne8x-1 bMgeRh" [innerHtml]="getHtml(descricao)">
                   
                  </div>
                </div>
              </div>
              <div class="cf2dg3-0 fTHriC"></div>
            </div>
           
            <span direction="vertical" class="uq5igk-0 hzecgz"></span>
          </div>
        </div>
      </div>
      </div>
</div>
       
<div class="col-sm-6">
    
</div>
