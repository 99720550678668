export class User{
    _id: string
    name: string
    email: string
    tipo: string
    password: string
    confirm_password: string
    accessToken: string
    nomeCurso: string
    cursoId: number
    descricaoCurso: string
}

export class FiltroUsuario{
    nome:string
}

export class UpdatePassword{
    email: string
    password: string
    newpassword: string
    confirmpassword: string
}