import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from 'src/app/user/user.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { error } from '@angular/compiler/src/util';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html'
})
export class ForgotpasswordComponent implements OnInit {
  
  forgotPasswordForm:FormGroup

  constructor(private userService: UserService,
              private router: Router,
              private fb: FormBuilder) { }

  ngOnInit() {

    this.forgotPasswordForm = this.fb.group({
      email: this.fb.control(''),

    })

  }

  sendMail(email:string){
    this.userService.sendMail(email).subscribe(result=>{

      if(result.message=="Email enviado!"){
        swal({
          title: 'Gypsum',
          text: 'Enviamos um e-mail com um link para você redefinir sua senha.',
          type: 'success',
          showConfirmButton: false,
          timer: 5000
        })
        setTimeout( () => {
          this.router.navigate(['/login'])
        }, 2500 );
      } else {
        console.log("sdfdfdf")
      }
    },(error)=>{
      swal({
        title: 'Gypsum',
        text: 'Email não cadastrado.',
        type: 'warning',
        showConfirmButton: false,
        timer: 2000
      })
    })
  }

}
