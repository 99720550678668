<div class="container">
  <div class="masonry-item col-md-7">
    <div class="bgc-white p-20 bd shadow">
      <h5 class="c-grey-900">Cadastro de Usuário</h5>
      <div class="mT-30">
        <form [formGroup]="cadastroForm">

          <div class="form-group">
            <label for="inputAddress">Nome</label>
            <input type="text" class="form-control" id="name" formControlName="name">
          </div>
          
          <div class="form-group">
            <label for="email">E-mail</label>
            <input type="text" class="form-control" id="email" formControlName="email">
          </div>
          
          <div class="form-group">
              <label for="email">Tipo</label>
              <select type="text" class="form-control" id="tipo" formControlName="tipo">
                  <option value="Administrador" selected>Administrador</option>
                  <option value="Conversa de Segurança">Conversa de Segurança</option>
              </select>
          </div>

          <!-- <div class="form-row">
            <div class="form-group col-md-6">
              <label for="password">Senha</label>
              <input type="password" class="form-control" id="password" formControlName="password">
            </div>
            <div class="form-group col-md-6">
              <label for="confirm_password">Confirmar Senha</label>
              <input type="password" class="form-control" id="confirm_password" formControlName="confirm_password">
            </div>
          </div> -->
    
          <div class="form-group has-feedback" style="text-align: center">
              <button (click)="gravar(cadastroForm.value)" type="submit" class="btn btn-info btn-sm"> Gravar </button>&nbsp;
              <button [routerLink]="['/dashboard/usuarios']" class="btn btn-danger btn-sm"> Cancelar </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
