import { Component, OnInit } from '@angular/core';
import { MenuService } from '../menu/menu.service';

@Component({
  selector: 'app-modulo-descircao',
  templateUrl: './modulo-descircao.component.html',
  styleUrls: ['./modulo-descircao.component.scss']
})
export class ModuloDescircaoComponent implements OnInit {

  descricao: any
  constructor(private menuService:  MenuService) { }

  ngOnInit(): void {
    this.descricao  = !this.menuService.getDescriptionModulo()? '' : this.menuService.getDescriptionModulo()
  }

}
