import {Injectable, EventEmitter} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Subject, Observable} from 'rxjs'
import {filter} from 'rxjs/operators'

import {POTENCIAL_API} from '../../app.api'
import {NavigationEnd, Router, ActivatedRoute} from "@angular/router";

@Injectable()
export class AlunoService {
  isLogged: EventEmitter<string> = new EventEmitter();
  lastUrl: string
  token = ""

  public jsonDataLogId = new Subject<any>();

  constructor(private http: HttpClient, 
            private route: Router, private router: ActivatedRoute) {
    this.route.events
        .pipe(filter(e => e instanceof NavigationEnd))
        .subscribe((e: NavigationEnd) => this.lastUrl = e.url)

        
  }

  createLog(data) {
    //passing the data as the next observable
    this.jsonDataLogId.next(data);
  }

  getIsLoggedEmitter() {
    return this.isLogged;
  }

  //Identificar se o usuario esta logado
  isLoggedIn(): boolean {    
    let user: any = localStorage.getItem("user")?JSON.parse(localStorage.getItem("user")):undefined

    if (user){
      this.isLogged.emit("isLogged");
    } else {
      this.isLogged.emit("notLogged");
    }
    return user !== undefined
  }

  user(): any {
    return window.localStorage.getItem("user")?JSON.parse(window.localStorage.getItem("user")):''
  }

  cadastro(user, token): Observable<any> {
    return this.http.put<any>(`${POTENCIAL_API}/api/alunos/${token}`, user)
  }

  handleLogin(path: string = this.lastUrl) {
    this.route.navigate(['/home', btoa(path)])
  }

  logout(type) {
    window.localStorage.removeItem('user')
    this.setLogIdle(type).subscribe(()=>{})
    this.isLogged.emit("notLogged");
    this.route.navigate(['login'])
  }

  obterFormandosDados(token): Observable<any> {
    return this.http.get<any>(`${POTENCIAL_API}/api/formandos/${token}`)
  }

  setLogIdle(type){
    return this.http.post(`${POTENCIAL_API}/api/logs/idle/create`, {type: type})
  }

}