import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { ForumService } from './forum.service';
import swal from 'sweetalert2';
import { POTENCIAL_API, BACKEND_FILE } from '../app.api';

import * as moment from 'moment';


@Component({
  selector: 'app-forum',
  templateUrl: './forum.component.html',
  styleUrls: ['./forum.component.scss']
})



export class ForumComponent implements OnInit {

  public Editor = ClassicEditor;
  public model: any = {text:""}
  public modelComments: any = {text:"", discussionId:""}
  public allDiscussions: any
  public allDiscussionsFixo: any
  discussionId: any
  subject: any
  comments: any = {}

  @ViewChild('modalForumNovo', {read: ElementRef}) modalForumNovo: ElementRef;
  @ViewChild('modalCommentsNovo', {read: ElementRef}) modalCommentsNovo: ElementRef;
  
  
  public editorConfig = {
    simpleUpload: {
        // The URL that the images are uploaded to.
        uploadUrl: `${BACKEND_FILE}/discussion`
    }
  }


  constructor(private forumService: ForumService) { }

  ngOnInit(): void {
    this.getDiscussions()
    this.getDiscussionsFixo()
  }

  openModal(event){
    
    if(!this.modalForumNovo.nativeElement.classList.contains("show")){
      this.modalForumNovo.nativeElement.setAttribute("style", "display: block;")
      this.modalForumNovo.nativeElement.classList.add("show")
    }else{
      this.modalForumNovo.nativeElement.setAttribute("style", "display: none;")
      this.modalForumNovo.nativeElement.classList.remove("show")
    }

  }

  openModalComments(event, discussionId, subject){
    this.discussionId = discussionId
    this.subject = subject

    if(!this.modalCommentsNovo.nativeElement.classList.contains("show")){
      this.modalCommentsNovo.nativeElement.setAttribute("style", "display: block;")
      this.modalCommentsNovo.nativeElement.classList.add("show")
    }else{
      this.modalCommentsNovo.nativeElement.setAttribute("style", "display: none;")
      this.modalCommentsNovo.nativeElement.classList.remove("show")
    }

  }

  closeModal(event){
    event.setAttribute("style", "display: none;")
    event.classList.remove("show")
  }

  saveDiscussion(model){
    let data = model
    this.forumService.createDiscussion(data).subscribe(result=>{
      swal({
        title: 'Potencial Global',
        text: "Duvida registrada com sucesso.",
        type: 'success',
        showConfirmButton: false,
        timer: 3000
      })
      this.modalForumNovo.nativeElement.setAttribute("style", "display: none;")
      this.modalForumNovo.nativeElement.classList.remove("show")
      this.getDiscussions()
    }, (error)=>{
      swal({
        title: 'Potencial Global',
        text: "Não foi possivel gravar o comentário. Por favor tente mais tarde.",
        type: 'error',
        showConfirmButton: false,
        timer: 3000
      })
      this.modalForumNovo.nativeElement.setAttribute("style", "display: none;")
      this.modalForumNovo.nativeElement.classList.remove("show")
    })

  }

  saveComments(model){
    let data = model
    data.discussionId = this.discussionId    
    this.forumService.createComments(data).subscribe(async result=>{
      swal({
        title: 'Potencial Global',
        text: "Duvida registrada com sucesso.",
        type: 'success',
        showConfirmButton: false,
        timer: 3000
      })
      this.modalCommentsNovo.nativeElement.setAttribute("style", "display: none;")
      this.modalCommentsNovo.nativeElement.classList.remove("show")
      
    }, (error)=>{
      swal({
        title: 'Potencial Global',
        text: "Não foi possivel gravar o comentário. Por favor tente mais tarde.",
        type: 'error',
        showConfirmButton: false,
        timer: 3000
      })
      this.modalCommentsNovo.nativeElement.setAttribute("style", "display: none;")
      this.modalCommentsNovo.nativeElement.classList.remove("show")
    })

  }

  getDiscussions(){
    swal.showLoading();
    this.forumService.getAllDiscussion().subscribe(result=>{      
      this.allDiscussions = result          
      swal.close()
    }, (error)=>{
      swal.close()
      swal({
        title: 'Potencial Global',
        text: "Não ha itens.",
        type: 'error',
        showConfirmButton: false,
        timer: 3000
      })
    })
  }

  getDiscussionsFixo(){
    swal.showLoading();
    this.forumService.getAllDiscussionFixo().subscribe(result=>{      
      this.allDiscussionsFixo = result          
      swal.close()
    }, (error)=>{
      swal.close()
      swal({
        title: 'Potencial Global',
        text: "Não ha itens.",
        type: 'error',
        showConfirmButton: false,
        timer: 3000
      })
    })
  }

  getFormatDate(strginDate){
    return moment(strginDate).format('DD-MM-YYYY HH:mm:ss')
 }

 

}
