import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

import { POTENCIAL_API } from '../app.api';


@Injectable()

export class RespostaService{

  constructor(private http: HttpClient){}
   
  /*cadastroFindId(id: string): Observable<CadastroBasico> {
    return this.http.get<CadastroBasico>(`${FOTONAUTAS_API}/get_user_id/${id}`)
  }*/

  // cadastroFindEmail(email: string): Observable<string> {
  //   return this.http.get<string>(`${POTENCIAL_API}/get_user/${email}`)
  // }

  checarEmail(email: string): Observable <boolean>{
    return this.http.get<boolean>(`${POTENCIAL_API}/v1/check_user_email/${email}`)
  }

  createAnswer(answers): Observable <any>{
      return this.http.post<any>(`${POTENCIAL_API}/api/questions/answer`, answers)
  }
  
  destroy(id): Observable<any> {
    return this.http.delete<any>(`${POTENCIAL_API}/v2/api/users/${id}`)
  }

  getRespostas(moduloId): Observable<any> {
    return this.http.get<any>(`${POTENCIAL_API}/api/questions/modulo/${moduloId}`)
  }
  
  obterPerguntaFoto(): Observable<any[]> {
    return this.http.get<any[]>(`${POTENCIAL_API}/files/pergunta`)
  }

  obterRespostaCursoModulo(cursoId): Observable<any[]> {
    return this.http.get<any[]>(`${POTENCIAL_API}/api/alunos/respostas/modulo/${cursoId}`)
  }
  
}