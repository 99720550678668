<div class="container" style="margin-top: 200px;">
  <div class="masonry-item col-md-6">
    <div class="col-md-12" style="text-align: center; vertical-align:baseline">
      <img src="assets/img/logo.png" alt="">
      <strong><label>Sistema de controle de incidentes</label></strong>
    </div>
    <div class="bgc-white p-20 bd shadow">
      <h5 class="c-grey-900">Redefinir Senha</h5>
      <div class="mT-30">
        <form [formGroup]="forgotPasswordForm">

          <div class="form-group">
              <label for="email">E-mail</label>
              <input type="text" class="form-control" id="email" formControlName="email" placeholder="Informe o e-mail cadastrado">
          </div>
    
          <div class="form-group has-feedback" style="text-align: center">
              <button (click)="sendMail(forgotPasswordForm.value.email)" class="btn btn-info btn-sm"> Redefinir Senha </button>&nbsp;
              <button [routerLink]="['/']" class="btn btn-danger btn-sm"> Cancelar </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>