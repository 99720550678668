import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

import { POTENCIAL_API } from '../app.api';
import { AlunoService } from '../security/aluno/aluno.service';


@Injectable()

export class DashboardService{

  constructor(private http: HttpClient){}
   
  createLogIdle(type: string): Observable <any>{
      return this.http.post<any>(`${POTENCIAL_API}/api/logs/idle/create`, type)
  }


  
  
}