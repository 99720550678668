<div class="row">
  <div class="col-md-12">
    <div class="bgc-white bd bdrs-3 p-20 mB-20">
      <h5 class="c-grey-900">Cadastro de Usuário</h5>
      <div class="mT-10">
        
        <table id="dataTable" class="table table-striped table-bordered" cellspacing="0" width="100%">
          <thead>
            <tr>
              <th style="width: 40%;">Nome</th>
              <th style="width: 35%;">E-mail</th>
              <th style="width: 20%;">Tipo</th>
              <th style="width: 5%;"></th>

            </tr>
          </thead>
          <tbody>
            <tr role="row" class="odd" style="height: 30px;" *ngFor="let usuario of usuarios | paginate: { 
              itemsPerPage: 10,
              currentPage: p}">
              <td>{{usuario.name}}</td>
              <td>{{usuario.email}}</td>
              <td>{{usuario.tipo}}</td>
              <td style='text-align: center'>
                <span (click)="update(usuario._id)" class="fa fa-edit mouseLink" style="color:green"></span>&nbsp;
                <span (click)="delete(usuario._id)" class="fa fa-trash mouseLink" style="color:red"></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-sm-8 pull-left">
        <button [routerLink]="['/dashboard/usuarios/cadastro']" class="btn btn-info btn-sm"> Novo Usuário </button>
      </div>
      <div class="col-sm-12 pull-left"><strong>
        <pagination-controls 
          (pageChange)="p = $event"
          autoHide="true"
          previousLabel="Anterior"
          nextLabel="Próximo"
          screenReaderPaginationLabel="Paginação"
          screenReaderPageLabel="página"
          screenReaderCurrentLabel="Você está na página">
        </pagination-controls> &nbsp;</strong>
      </div>
      <br>
    </div>
  </div>
</div>