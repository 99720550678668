<!-- INÍCIO DO CONTEÚDO -->
<section class="content-header">
</section>

<!-- Main content -->
<section class="content">

  <div class=""  style="text-align: center;">
    <!--<img src="assets/img/404.png"/>-->
    <br><br><br><br>
    <h1>Página não encontrada!</h1>
  </div>
</section>
<!-- FIM DO CONTEÚDO -->