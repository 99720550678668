import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';

import { RouterModule } from '@angular/router'
import { ROUTES } from './app.routes'
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { NgxMaskModule } from 'ngx-mask'
import { NgxPaginationModule } from 'ngx-pagination'
import { QRCodeModule } from 'angular2-qrcode';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

import {CURRENCY_MASK_CONFIG, CurrencyMaskConfig} from "ng2-currency-mask/src/currency-mask.config"

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { LoginComponent } from './security/login/login.component';
import { LoginService } from './security/login/login.service';
import { AuthInterceptor } from './security/auth.interceptor';

import { LoggedInGuard } from './security/loggedin.guard';

import { DashboardComponent } from './dashboard/dashboard.component';
import { MenuComponent } from './menu/menu.component';
import { UsuariosComponent } from './user/user.component';
import { FormUserComponent } from './user/formuser/formuser.component';
import { UserService } from './user/user.service';
import { ForgotpasswordComponent } from './security/forgotpassword/forgotpassword.component';
import { ChangepasswordComponent } from './security/changepassword/changepassword.component';
import { RedefinepasswordComponent } from './security/redefinepassword/redefinepassword.component';




import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CursoPdfComponent } from './curso/curso-pdf/curso-pdf.component';
import { AvaliacaoComponent } from './curso/avaliacao/avaliacao.component';
import { AlunoComponent } from './security/aluno/aluno.component';
import { AlunoService } from './security/aluno/aluno.service';
import { CursoService } from './curso/curso.service';
import { RespostasComponent } from './respostas/respostas.component';
import { RespostaService } from './respostas/respostas.service';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { VideoComponent } from './curso/conteudo/video/video.component';
import { ConteudoComponent } from './conteudo/conteudo.component';
import { MenuMobileComponent } from './menu-mobile/menu-mobile.component';
import { ModuloDescircaoComponent } from './modulo-descircao/modulo-descircao.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component'
import { MenuService } from './menu/menu.service';
import { ForumComponent } from './forum/forum.component';
import { ForumService } from './forum/forum.service';
import { CommentListComponent } from './forum/comment-list/comment-list.component';
import { DashboardService } from './dashboard/dashboard.service';
import { IframeComponent } from './curso/conteudo/iframe/iframe.component';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "left",
  allowNegative: false,
  decimal: ",",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: "."
};

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    FooterComponent,
    
    FormUserComponent,
    HeaderComponent,    
    HomeComponent,
    LoginComponent,
    MenuComponent,
    NotFoundComponent,
    RedefinepasswordComponent,
    
    UsuariosComponent,
    ChangepasswordComponent,
    ForgotpasswordComponent,
    CursoPdfComponent,
    AvaliacaoComponent,
    AlunoComponent,
    RespostasComponent,
    VideoComponent,
    IframeComponent,
    ConteudoComponent,
    MenuMobileComponent,
    ModuloDescircaoComponent,
    ProgressBarComponent,
    ForumComponent,
    CommentListComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    NgIdleKeepaliveModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgxPaginationModule,
    QRCodeModule,
    ReactiveFormsModule,
    RouterModule.forRoot(ROUTES,  { onSameUrlNavigation: 'ignore' }),
    NgbModule,
    SlickCarouselModule,
    PdfViewerModule,
    NgxDocViewerModule,
    CKEditorModule
  
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    LoginService, UserService, AlunoService, CursoService,  LoggedInGuard, 
      RespostaService, MenuService, ForumService, DashboardService,
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }