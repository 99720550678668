import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../security/login/login.service';
import { CursoService } from '../curso/curso.service';

@Component({
  selector: 'app-conteudo',
  templateUrl: './conteudo.component.html',
  styleUrls: ['./conteudo.component.scss']
})
export class ConteudoComponent implements OnInit {

  conteudos: any={}
  cursoId: any
  conteudoFinalizado: any

  constructor(private route: Router,  
    private router: ActivatedRoute,
    private loginService: LoginService,
    private cusroServide: CursoService) { 
      this.cursoId = this.loginService.user().cursoId
    }

  ngOnInit(): void {
    this.router.params.subscribe(
      params => {
          const id = +params['conteudoId'];
          this.conteudos = !window.localStorage.getItem("conteudos") ? {} : JSON.parse(window.localStorage.getItem("conteudos"))
          //this.getMovie(id);
          console.log("acionamento da rota")
          this.obterConteudoStatus()
      }
    );
    
    
  }

  ngAfteViewInit(){
    this.conteudos = !window.localStorage.getItem("conteudos") ? {} : JSON.parse(window.localStorage.getItem("conteudos"))
  }



  //01/04/2020 - mudança pra realizar a apresentação do conteudo em cards.
  //este metodo podes ir para um modulo mais generico.
  goConteudo(link, conteudo, tipo, conteudoId, conteudoItemId){
    //usar assim par fazer refresh no componente
    console.log("conteudo", conteudo)
    switch (tipo) {
      case 'A':
        // this.route.routeReuseStrategy.shouldReuseRoute = () => false
        this.route.navigate(['area-aluno/cursos/conteudo',this.cursoId, conteudoId,conteudoItemId,link])
        break;
      case 'Y':
          // this.route.routeReuseStrategy.shouldReuseRoute = () => false
          this.route.navigate(['area-aluno/cursos/conteudo/video',this.cursoId,conteudoId, conteudoItemId,conteudo ])
        break;
      case 'G':
          window.open(conteudo, '_blank');
        break;
      case 'V':
        // this.route.routeReuseStrategy.shouldReuseRoute = () => false
        this.route.navigate(['area-aluno/cursos/conteudo/video',this.cursoId,conteudoId, conteudoItemId,link ])
      break;

      case 'I':
        // this.route.routeReuseStrategy.shouldReuseRoute = () => false
        this.cusroServide.setConteudoIframe(conteudo)
        this.route.navigate(['area-aluno/cursos/conteudo-embedded',this.cursoId, conteudoId,conteudoItemId])
        break;
      default:
        break;
    }
   
  }

  obterConteudoStatus(){
    this.cusroServide.obterConteudoStatus().subscribe(conteudoStatus=>{
      console.log(conteudoStatus)
      this.conteudoFinalizado = conteudoStatus
    })

  }

  checkconteudoStatus(conteudoId, conteudoItemId){
    let status: boolean = false
    if(this.conteudoFinalizado){
      let conteudoItem = this.conteudoFinalizado.find(conteudo => conteudo.capituloId === conteudoId.toString())
      if(conteudoItem != undefined && conteudoItem.conteudo.findIndex(x => x.conteudoItemId === conteudoItemId.toString()) >=0){
        status = true
      }else{
        status = false    
      }
    }
    return  status
  }

  setCurrentContent(){
    this.cusroServide.setCurrentContent(this.route.url, 1)    
  }

}
