import { Component, OnInit } from '@angular/core';
import { LoginService } from '../security/login/login.service';
import { AlunoService } from '../security/aluno/aluno.service';
import { DashboardService } from './dashboard.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

import swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {

  tituloCurso: any
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null; 

  constructor(private loginService: LoginService, 
    private alunoService: AlunoService,
    private dashboardService: DashboardService, 
    private idle: Idle, 
    private route: Router,
    private keepalive: Keepalive) { 
    this.tituloCurso = this.loginService.user().nomeCurso

    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(900); // 15min
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(10);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => { 
      this.idleState = 'No longer idle.'
      console.log(this.idleState);
      this.reset();
    });
    
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      swal.close()
      this.loginService.setLogIdle('TIMEOUT').subscribe(()=>{
        this.loginService.logout()
      })
      
      console.log(this.idleState);      
    });
    
    idle.onIdleStart.subscribe(() => {
        this.idleState = 'You\'ve gone idle!'
        console.log(this.idleState);
        swal({
          title: 'Potencial Global',
          text: "Estas online ?",
          type: 'warning',
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText:'CONFIRMAR',
        }).then(cancell=>{
          this.reset()
        })
        // this.childModal.show();
    });
    
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
      console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();
  }

  ngOnInit() {
    if(!this.loginService.isLoggedIn()){
      this.route.navigate(['/login'])
    }
  }


  chengeStatus(){
    console.log("Emitiu evento")
  }

  collapsedMenu(event){
       
    console.log(event)
    if(!event.target.parentElement.parentElement.classList.contains("active")){
      event.target.parentElement.parentElement.classList.add("active")
    }else{
      event.target.parentElement.parentElement.classList.remove("active")     
    }

  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }



}
