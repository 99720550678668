
<div class="hnqxaA">
  <div class="col-sm eVeexS">
   <!--  <h6 class="t3v5br-0 o88qkk-1 kHGMHR">Introduction</h6> -->
   <ul class="list-group">
        <li class="list-group-item ">
            <app-progress-bar [total]="quantidadeTotalModulos" [quantidade]="quantidadeModulosRespondidos"></app-progress-bar>
        </li>
        <li class="list-group-item sidebar-separator-title text-muted d-flex align-items-center menu-collapsed">
            <small><a [routerLink]="['cursos/forum']">FORUM</a></small>
            <!-- &nbsp;<span class="badge badge-secondary">2</span> -->
        </li>
       <li class="list-group-item sidebar-separator-title text-muted d-flex align-items-center menu-collapsed">
           <small>CONTEUDO</small>
       </li>
       <a routerLink="/area-aluno"  class="bg-white list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-start align-items-center">
                <span class="fa fa fa-home fa-fw mr-3"></span>
                <span class="menu-collapsed">Apresentação do curso</span>
            </div>
        </a>

        <ng-container *ngFor="let modulo of modulos; let indexModulo = index;">
            <ng-container *ngIf="indexModulo == 0">
                <a  id='submenuClick{{indexModulo +1}}'  
                data-toggle="collapse" 
                aria-expanded="false" 
                (click)="collapsedMenu($event, indexModulo + 1, modulo.descricao)" 
                class="bg-white list-group-item list-group-item-action flex-column align-items-start"
                 >

                    <div class="d-flex w-100 justify-content-start align-items-center">
                        <span class="fa fa-book fa-fw mr-3"></span>
                        <span class="menu-collapsed">{{modulo.nome}}</span>
                        <span class="submenu-icon ml-auto"></span>
                    </div>
                    <div class="col" *ngIf="checkRespostas(modulo.id)">   
                        <div class="icon-holder" title="Concluído">
                          <i class="fa fa-check-circle-o" style="color: green; padding: 10px;"></i>
                        </div> 
                    </div>
                </a>
               
          
                <div #submenuCollaps id='submenu{{indexModulo +1}}' 
                name='submenu{{indexModulo +1}}' 
                class="collapse sidebar-submenu" 
                [ngClass]="{show: (submenuCollaps.id == isCollapsed)? true : false}" >
                    <a class="list-group-item list-group-item-action list-group-item-sub" 
                    [ngClass]="{'list-group-item-active': (capitulo.id == conteudoActive)? true : false}"
                    (click)="changeRoute(['/area-aluno/cursos/conteudos'], capitulo, capitulo.id)" 
                    *ngFor="let capitulo of sortConteudo(modulo.Capitulo)"  >
                        <span class="menu-collapsed">{{capitulo.nome}}</span>
                        <span class="icon-holder ml-1" *ngIf="capitulo.id == checkConteudolFullStatus(capitulo.id, capitulo.capituloconteudos)">
                            <i class="fa fa-check-circle-o" style="color: green;"></i>
                        </span>
                     
                    </a>
                    <a class="list-group-item list-group-item-action list-group-item-sub" 
                    (click)="goPerguntas(modulo.id)">
                        <span class="menu-collapsed">Teste</span>
                        <span class="icon-holder ml-1" *ngIf="checkRespostas(modulo.id)">
                            <i class=" fa fa-check-circle-o" style="color: green;"></i>
                        </span>
                    </a>      
                </div>
            </ng-container>
            <ng-container *ngIf="indexModulo > 0 && checkModuloLiberar(indexModulo)">
                <a  id='submenuClick{{indexModulo +1}}'  
                data-toggle="collapse" 
                aria-expanded="false" 
                (click)="collapsedMenu($event, indexModulo + 1, modulo.descricao)" 
                class="bg-white list-group-item list-group-item-action flex-column align-items-start"  >

                    <div class="d-flex w-100 justify-content-start align-items-center">
                        <span class="fa fa-book fa-fw mr-3"></span>
                        <span class="menu-collapsed">{{modulo.nome}}</span>
                        <span class="submenu-icon ml-auto"></span>
                    </div>
                    <div class="col" *ngIf="checkRespostas(modulo.id)">   
                        <div class="icon-holder" title="Concluído">
                          <i class="fa fa-check-circle-o" style="color: green; padding: 10px;"></i>
                        </div> 
                    </div>
                </a>
          
                <div #submenuCollaps id='submenu{{indexModulo +1}}' 
                name='submenu{{indexModulo +1}}' 
                class="collapse sidebar-submenu" 
                [ngClass]="{show: (submenuCollaps.id == isCollapsed)? true : false}"  >
                    <a class="list-group-item list-group-item-action list-group-item-sub" 
                    (click)="changeRoute(['/area-aluno/cursos/conteudos'],  capitulo, capitulo.id)" 
                    *ngFor="let capitulo of sortConteudo(modulo.Capitulo)" >
                        <span class="menu-collapsed">{{capitulo.nome}}</span>
                        <span class="icon-holder ml-1" *ngIf="capitulo.id == checkConteudolFullStatus(capitulo.id, capitulo.capituloconteudos)">
                            <i class="fa fa-check-circle-o" style="color: green;"></i>
                        </span>                        
                    </a>
                    <a class="list-group-item list-group-item-action list-group-item-sub" 
                    (click)="goPerguntas(modulo.id)">
                        <span class="menu-collapsed">Teste</span>
                        <span class="icon-holder ml-1" *ngIf="checkRespostas(modulo.id)">
                            <i class=" fa fa-check-circle-o" style="color: green;"></i>
                        </span>
                    </a>      
                </div>
            </ng-container>
            <ng-container *ngIf="indexModulo > 0 && !checkModuloLiberar(indexModulo)">               
                <div class="bg-white list-group-item list-group-item-action flex-column align-items-start">
                    <div class="d-flex w-100 justify-content-start align-items-center">
                        <span class="fa fa-dashboard fa-fw mr-3"></span>
                        <span class="menu-collapsed">{{modulo.nome}}</span>
                        <span class="submenu-icon ml-auto"></span>
                    </div>
                </div>
            </ng-container>
            
        </ng-container>
        

   </ul>
   
   
  </div>
</div>

