<div class="row">
    <div class="col-sm my-2"  *ngFor="let conteudoDocs of conteudos.capituloconteudos">
        <div class="card card-hover" style="width: 18rem;" (click)="goConteudo(conteudoDocs.link, conteudoDocs.conteudo, conteudoDocs.tipo, conteudos.id, conteudoDocs.id)"  >
            <img class="card-img-top" src="../assets/img/conteudos.jpg" alt="Card image cap">
            <div class="card-body">
              <h5 class="card-title">
                {{conteudoDocs.nome}}
              </h5>
                <p class="card-text"></p>
                
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                      <a class='sidebar-link' (click)="goConteudo(conteudoDocs.link, conteudoDocs.conteudo, conteudoDocs.tipo, conteudos.id, conteudoDocs.id)" *ngIf="conteudoDocs.tipo ==='A'">
                        <span class="icon-holder mr-3">
                          <i class="c-deep-orange-500 fa fa-file"></i>
                        </span>
                        <span class="title" style="cursor: pointer;">{{conteudoDocs.nome}}</span>&nbsp;
                      
                      </a> 
                      <a  class="card-link" (click)="goConteudo(conteudoDocs.link, conteudoDocs.conteudo, conteudoDocs.tipo, conteudos.id, conteudoDocs.id)" *ngIf="conteudoDocs.tipo ==='Y'">
                          <span class="icon-holder mr-3">
                            <img style="width: 20px;" src="assets/img/yt_icon_rgb.png">
                          </span>
                          <span class="title " style="cursor: pointer;">{{conteudoDocs.nome}}</span>&nbsp;
                      
                        </a>
                        <a  class="card-link" (click)="goConteudo(conteudoDocs.link, conteudoDocs.conteudo, conteudoDocs.tipo, conteudos.id, conteudoDocs.id)" *ngIf="conteudoDocs.tipo ==='V'">
                          <span class="icon-holder mr-3">
                            <img style="width: 20px;" src="assets/img/icon_vimeo.png">
                          </span>
                          <span class="title " style="cursor: pointer;">{{conteudoDocs.nome}}</span>&nbsp;
                      
                        </a> 
                        <a  class="card-link" (click)="goConteudo(conteudoDocs.link, conteudoDocs.conteudo, conteudoDocs.tipo, conteudos.id, conteudoDocs.id)" *ngIf="conteudoDocs.tipo ==='G'">
                          <span class="icon-holder mr-3">
                            <i class="c-deep-orange-500 fa fa-google"></i>
                          </span>
                          <span class="title " style="cursor: pointer;">{{conteudoDocs.nome}}</span>&nbsp;
                    
                        </a> 
                        <span class="icon-holder" *ngIf="checkconteudoStatus(conteudos.id, conteudoDocs.id)">
                          <i class=" fa fa-check-circle-o" style="color: green;"></i>
                        </span>
                  </li>
              </ul>
            </div>
            

            <!-- <img class="card-img-top" src="assets/image/download.svg" alt="Card image cap">
            <div class="card-body">
            <h5 class="card-title">Manual</h5>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            <a href="#" class="btn btn-primary">Go somewhere</a>
            </div> -->
        </div>
    </div>
    <div class="col-sm"></div>
</div>
