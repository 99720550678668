// export const POTENCIAL_API = "http://localhost:8082"
// export const BACKEND_FILE = "http://localhost:8082/files"

// export const POTENCIAL_API = "http://192.168.1.67:8082"
// export const BACKEND_FILE = "http://192.168.1.67:8082/files"

// auto financiada
export const POTENCIAL_API = "https://bpotencial-global.leafdev.com.br" 
export const BACKEND_FILE = "https://bpotencial-global.leafdev.com.br/files"

//financiada
// export const POTENCIAL_API = "https://backend.epg.leafdev.com.br" 
// export const BACKEND_FILE = "https://backend.epg.leafdev.com.br/files"