import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { POTENCIAL_API } from '../app.api';



@Injectable()
export class MenuService {

  public jsonDataProgressBar = new Subject<string>();

  passValue(data) {
    //passing the data as the next observable
    this.jsonDataProgressBar.next(data);
  }

  setDescriptionModulo(descricao){
    window.localStorage.setItem("moduloDescricao", descricao)
  }

  getDescriptionModulo(){
    return  !window.localStorage.getItem("moduloDescricao")? '' : window.localStorage.getItem("moduloDescricao") 
  }


  
  
}

